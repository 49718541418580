import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthProvider/AuthProvider";
import { rolesDashboards } from "./rolesDashboard";

export const PublicRoute: React.FC<{ children: React.ReactNode }> = (props) => {
  const { user } = useAuth();

  if (user.isLogin && user.tokens) {
    const path = rolesDashboards[user.role as "ADMIN"];
    if (path) {
      return <Navigate to={path} />;
    }
  }
  return <>{props.children}</>;
};
