import { Button, Grid } from "@mui/material";
import React from "react";
import { StandadCard } from "../../../../components/Cards";
import { StandardCellList } from "../../../../components/Standards";
import { ICompletedRequestProps, ICompletedUpgradeRequestProps } from "./interfaces/ICompletedRequestProps";

export const CompletedUpgradeRequest: React.FC<ICompletedUpgradeRequestProps> = (props) => {
  const { product, productLink, generatedLink } = props;

  return (
    <>
      <StandadCard heading="Generated Link Information">
        <StandardCellList
          colSize={6}
          list={[
            {
              data: {
                type: "text",
                value: (
                  <a rel="noreferrer" href={generatedLink.url} target="_blank">
                    {generatedLink.url}
                  </a>
                ),
              },
              heading: "Buy Link",
            },
            {
              data: {
                type: "text",
                value: generatedLink.user_id,
              },
              heading: "User ID",
              
            },
            {
              data: {
                type: "text",
                value: generatedLink.membership_id,
              },
              heading: "Membership ID",
            },
            {
              data: {
                type: "text",
                value: generatedLink.msg,
                color: "success",
              },
              heading: "Message",
            },
          ]}
        />
      </StandadCard>
      {/* <StandadCard sx={{ mt: 2 }} heading="Selected Product Information">
        <StandardCellList
          colSize={3}
          list={[
            {
              data: {
                type: "text",
                value: product?.package_id,
              },
              heading: "# Product ID",
            },
            {
              data: {
                type: "text",
                value: product?.package_name,
              },
              heading: "Name",
            },
            {
              data: {
                type: "text",
                value: product?.package_price_excluding_gst,
              },
              heading: "Price excluding GST",
            },
            {
              data: {
                type: "text",
                value: `${product?.package_price}  : GST ${product?.gst_amount}(${product?.gst_percentage}%)`,
                color: "error.main",
              },
              heading: "Price With GST",
            },
            {
              data: {
                type: "text",
                value: product?.no_of_services,
              },
              heading: "Services",
            },
            {
              data: {
                type: "text",
                value: product?.duration,
              },
              heading: "Duration",
            },
          ]}
        />
      </StandadCard> */}

      <Grid container spacing={3} sx={{ mt: 2 }}>
        <Grid item xs={12} lg={7}>
          <StandadCard heading="User Information">
            <StandardCellList
              colSize={4}
              list={[
                {
                  data: {
                    type: "text",
                    value: productLink.user_name_v,
                  },
                  heading: "Customer Name",
                },
                {
                  data: {
                    type: "text",
                    value: productLink.mobile_number_v,
                  },
                  heading: "Mobile Number",
                },
                {
                  data: {
                    type: "text",
                    value: productLink.email_id_v,
                  },
                  heading: "Email",
                },
                {
                  data: {
                    type: "text",
                    value:
                      productLink.state_v +
                      " / " +
                      productLink.city_v +
                      " / " +
                      productLink.area_v,
                  },
                  heading: "State / City / Area",
                },
                {
                  data: {
                    type: "text",
                    value: productLink.address_v,
                  },
                  heading: "Address",
                },
                {
                  data: {
                    type: "text",
                    value: productLink.p_code_v,
                  },
                  heading: "Promo Code",
                },
              ]}
            />
          </StandadCard>
        </Grid>
        <Grid item xs={12} lg={5}>
          <StandadCard heading="Vehicle Information">
            <StandardCellList
              colSize={6}
              list={[
                {
                  data: {
                    type: "text",
                    value: productLink.vehicle_no_v,
                  },
                  heading: "Vehcile Number",
                },
                {
                  data: {
                    type: "text",
                    value: productLink.maker_name_v,
                  },
                  heading: "Brand",
                },
                {
                  data: {
                    type: "text",
                    value: productLink.model_name_v,
                  },
                  heading: "Model",
                },
              ]}
            />
          </StandadCard>
        </Grid>
      </Grid>
      <Grid container spacing={3} sx={{ mt: 2 }}>
         <Grid item xs={12} lg={4}/>
         <Grid item xs={12} lg={4}>
              <Button variant='contained' fullWidth onClick={props.onCreateNew}>Close</Button>
         </Grid>
         <Grid item xs={12} lg={4}/>
      </Grid>
    </>
  );
};
