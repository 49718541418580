import React from "react";
import { IStandardCellListProps } from "./interfaces/IStandards";
import { Box, Grid } from "@mui/material";
import { StandardCardCell } from "./StandardCell";

export const StandardCellList: React.FC<IStandardCellListProps> = (props) => {
  const { list, colSize } = props;
  return (
    <Box>
      <Grid container spacing={1}>
        {list.map((item) => {
          if (!item.isHide) {
            return (
              <Grid key={item.heading} item xs={12} md={colSize}>
                <StandardCardCell
                  heading={item.heading}
                  isHide={item.isHide}
                  data={item.data}
                />
              </Grid>
            );
          }
          return null;
        })}
      </Grid>
    </Box>
  );
};
