import moment from "moment";

export const dateFilterDates = (format = "YYYY-MM-DD") => {
  const today = moment().format(format);
  const tommorow = moment().add(1, "days").format(format);
  const yesterday = moment().subtract(1, "days").format(format);
  const lastweek = moment().subtract(1, "weeks").format(format);
  const lastMonthStart = moment().subtract(1, "month").startOf("month").format(format);
  const lastMonthEnd = moment().subtract(1, "month").endOf("month").format(format);
  const last30Days = moment().subtract(30, "days").format(format);
  const currentDate = moment();
  const startDateOfMonth = currentDate.startOf("month").format(format);

  const thisWeekStartDate = moment().clone().startOf("isoWeek").format(format);

  // Get the ending date (Sunday) of this week
  const thisWeekEndDate = moment().clone().endOf("isoWeek").format(format);



  return {
    today,
    tommorow,
    yesterday,
    lastweek,
    thisWeekStartDate,
    thisWeekEndDate,
    lastMonthStart,
    lastMonthEnd,
    last30Days,
    startDateOfMonth,
  };
};
