import React from "react";
import { useAuth, userRoles } from "../AuthProvider/AuthProvider";
import { AgentDialog } from "./AgentDialog";

export interface IAgentContext {
  name: string;
}

const AgentContext = React.createContext<IAgentContext>({
  name: "",
});

export const useCustomerCareContext = () => React.useContext(AgentContext);

export const AgentProvider: React.FC<{ children: React.ReactNode }> = (
  props
) => {
  const { user, addUserName } = useAuth();
  const [open, setOpenDialog] = React.useState<boolean>(true);

  const handleName = (name: string) => {
    addUserName(name);
    setOpenDialog(false);
  };

  if (!user.isLogin) {
    return <>{props.children}</>;
    //@ts-ignore
  } else if (user.isLogin && user.role === userRoles.AGENT && open) {
    return (
      <AgentDialog open={open} onClose={(newName) => handleName(newName)} />
    );
  } else {
    return <>{props.children}</>;
  }
};
