import {
  AspectRatio,
  AssignmentInd,
  Check,
  Close,
  DoNotDisturbOn,
  PendingActions,
  Warning,
} from "@mui/icons-material";
import { Avatar, Box, Chip, MenuItem, Tooltip } from "@mui/material";
import { capitalize } from "lodash";
import React from "react";
import { api } from "../../api/api";
import { PageContainer } from "../../components/container/PageContainer";
import { ContextMenu } from "../../components/ContextMenu/ContextMenu";
import { DataTable } from "../../components/Table/DataTable";
import {
  IDataTableProps,
  MobileLogoRenderType,
  RenderType,
} from "../../components/Table/interfaces/IDataTableProps";
import { RefreshPlugin } from "../../components/Table/plugins";
import { CSVExportPluginonPagination } from "../../components/Table/plugins/CSVExportPlugin/CSVExportPluginonPagination";
import { ICSVDataFormat } from "../../components/Table/plugins/CSVExportPlugin/interfaces/ICSVExportPluginProps";
import { useAuth } from "../../contexts/AuthProvider/AuthProvider";
import { useMessagesPopup } from "../../contexts/MessagesPopupProvider/MessagesPopupProvider";
import { truncate } from "../../helpers";
import { Breadcrumb } from "../../layout/fullLayout/breadcrumb/Breadcrumb";

import { CcAssignComplaint } from "./components/AssignComplaint/CcAssignCompliant";
import { ICcAssignComplaint } from "./components/AssignComplaint/interfaces/ICcAssignComplaint";
import { ViewInfoDialog } from "./components/ViewInfoDialog/ViewInfoDialog";
import { IRSAUser } from "./interfaces/IRSAList";
import { ICcCloseComplaint } from "./components/CloseComplaint/interfaces/ICcCloseComplaint";
import { CcCloseComplaint } from "./components/CloseComplaint/CcCloseCompliant";
import { IMenuOption } from "../../components/ContextMenu/interfaces/IContextMenuProps";
import { CustomChip } from "../../components/CustomChip";
import dayjs from "dayjs";
import { CustomSelect } from "../../components/formsComponents";
import { io } from "socket.io-client";
import alarmAudio from "../../assets/audios/alarm.mp3";
import { useSocket } from "../../contexts/SocketContext/SocketContext";
import { Socket } from "dgram";

export const RSAList: React.FC = () => {
  const BCrumb = [
    {
      to: "/",
      title: "cxo-main",
    },
    {
      title: "rsa-users-list",
    },
  ];

  const [rsaList, setRSAList] = React.useState<IRSAUser[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [openView, setOpenView] = React.useState<IRSAUser | null>(null);
  const [openAssign, setOpenAssign] = React.useState<ICcAssignComplaint | null>(
    null
  );
  const [openClose, setOpenClose] = React.useState<ICcCloseComplaint | null>(
    null
  );
  const [status, setStatus] = React.useState("ACTIVE");
  const { user } = useAuth();
  const { showTopErrorSnackbar, showTopSuccessSnackbar, showSuccessMessage } =
    useMessagesPopup();
  const [tablePagination, setTablePagination] = React.useState({
    pageNumber: 1,
    rowsInPerPage: 50,
  });
  const [totalRecords, setTotalRecords] = React.useState(0);
  const { socket } = useSocket();

  const fetchRSAUsersList = async () => {
    try {
      if (user.isLogin && user.tokens && user.tokens.access) {
        setLoading(true);
        const response = await api.get(
          `/get-quick-rsa-list?page=${tablePagination.pageNumber}&size=${tablePagination.rowsInPerPage}&status=${status}`,
          {
            headers: {
              "auth-Token": user.tokens.access,
            },
          }
        );
        setRSAList(response.data.result);
        setTotalRecords(response.data.total.totalDataLength);
      }
    } catch (error: any) {
      showTopErrorSnackbar(error.response.data.msg);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchRSAUsersList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tablePagination, openAssign, openClose, status, socket]);

  //socket setup
  // React.useEffect(() => {
  //   if (socket) {
  //     socket.on("newRsaRecord", () => {
  //       const audio = new Audio(alarmAudio);

  //       audio.play();
  //       showTopSuccessSnackbar("A new RSA request detected!");
  //       showSuccessMessage(<Box>New Record Found</Box>);

  //       fetchRSAUsersList();
  //     });
  //   }

  //   return () => {
  //     if (socket) {
  //       socket.off("newRsaRecord"); // Clean up the socket event listener
  //     }
  //   };
  // }, [socket, showTopSuccessSnackbar]);

  const fetchAllData = async (): Promise<ICSVDataFormat[]> => {
    const res = await api.get(`/get-quick-rsa-list`);
    const data: ICSVDataFormat[] = res.data.result;
    return data;
  };

  const rsaTableProps: IDataTableProps = {
    isPagination: true,
    isDataLoading: loading,
    totalRecords: totalRecords,
    rowsPerPageOptions: tablePagination.rowsInPerPage,
    tableCommandBarProps: {
      leftItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <CustomSelect
                sx={{ minWidth: "120px" }}
                value={status}
                onChange={(e) => setStatus(e.target.value as string)}
              >
                <MenuItem value="ACTIVE">ACTIVE</MenuItem>
                <MenuItem value="CLOSED">CLOSED</MenuItem>
              </CustomSelect>
            ),
          },
          {
            key: "refresh",
            onRender: () => (
              <RefreshPlugin
                onClick={() => {
                  fetchRSAUsersList();
                }}
              />
            ),
          },
          {
            key: "csvExport",
            onRender: (columns, items) => (
              <CSVExportPluginonPagination
                columns={[
                  { columnName: "RSA Help ID", fieldName: "quick_rsa_help_id" },
                  { columnName: "Vehicle No.", fieldName: "vehicle_no" },
                  { columnName: "Mobile Number", fieldName: "mobile_no" },
                  { columnName: "Assigned To", fieldName: "assign_to_name" },
                  { columnName: "Address", fieldName: "address", width: 30 },
                  { columnName: "Status", fieldName: "status" },
                ]}
                onExportButtonClick={fetchAllData}
                filePrefixName="Report"
              />
            ),
          },
        ],
      },
      rightItems: {
        plugins: {
          searchField: {
            searchKeys: [
              "quick_rsa_help_id",
              "address",
              "mobile_no",
              "vehicle_no",
              "status",
              "comment",
              "insert_dt",
            ],
            items: rsaList,
          },
        },
      },
    },
    mobileLogo: {
      type: MobileLogoRenderType.reactNode,
      onMobileLogoRender: (data: any) => {
        return (
          <Avatar
            sx={{
              width: 45,
              height: 45,
              color: "#fff",
              ml: "-8px",
              mr: 2,
            }}
          ></Avatar>
        );
      },
    },
    columns: [
      {
        key: "actions",
        headerName: "Actions",
        fieldName: "",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row) => {
          const isClosed = row.status === "CLOSED";
          const menuOptions = [
            {
              icon: <AspectRatio />,
              label: "See Details",
              onClick: () => setOpenView(row),
            },
            !isClosed && {
              icon: <AssignmentInd />,
              label: "Assign Complaint",
              onClick: () => setOpenAssign(row),
            },
            !isClosed && {
              icon: <DoNotDisturbOn />,
              label: "Close Complaint",
              onClick: () => setOpenClose(row),
            },
          ].filter(Boolean) as IMenuOption[]; // Filter out falsy values and assert the type

          return <ContextMenu menuOptions={menuOptions} />;
        },
      },
      {
        key: "quick_rsa_help_id",
        headerName: "RSA Help ID",
        fieldName: "quick_rsa_help_id",
        renderType: RenderType.TEXT,
      },
      {
        key: "vehicle_no",
        headerName: "Vehicle No.",
        fieldName: "vehicle_no",
        renderType: RenderType.TEXT,
      },

      {
        key: "mobile_no",
        headerName: "Mobile Number",
        fieldName: "mobile_no",
        renderType: RenderType.TEXT,
      },
      {
        key: "assign_to_name",
        headerName: "Assigned To",
        fieldName: "assign_to_name",
        renderType: RenderType.TEXT,
        onRowCellRender: (value) => {
          // console.log("Assigned To value:", value);
          const assign_to_name = value;

          // Check if the value is empty
          if (!assign_to_name || !assign_to_name.value) {
            return "-"; // Return null if there is no value
          }

          return (
            <Chip
              label={`${assign_to_name.value}`}
              color="success"
              size="medium"
              sx={{ fontSize: "15px" }}
            />
          );
        },
      },
      {
        key: "create_dt",
        headerName: "Created at",
        fieldName: "create_dt",
        exportCellWidth: 25,
        renderType: RenderType.DATE_TIME,
        onRowCellRender: (value) => {
          if (!value || !value.value) return "--";
          const dateValue = value && value.value ? value.value : value;
          return dateValue
            ? dayjs(dateValue).format("YYYY-MM-DD hh:mm:ss A")
            : "--";
        },
      },
      {
        key: "assign_time",
        headerName: "Assigned at",
        fieldName: "assign_time",
        exportCellWidth: 25,
        renderType: RenderType.DATE_TIME,
        onRowCellRender: (value) => {
          if (!value || !value.value) return "--";
          const dateValue = value && value.value ? value.value : value;
          return dateValue
            ? dayjs(dateValue).format("YYYY-MM-DD hh:mm:ss A")
            : "--";
        },
      },
      {
        key: "close_time",
        headerName: "Closed at",
        fieldName: "close_time",
        exportCellWidth: 25,
        renderType: RenderType.DATE_TIME,
        onRowCellRender: (value) => {
          if (!value || !value.value) return "--";
          const dateValue = value && value.value ? value.value : value;
          return dateValue
            ? dayjs(dateValue).format("YYYY-MM-DD hh:mm:ss A")
            : "--";
        },
      },
      {
        key: "address",
        headerName: "Address",
        fieldName: "address",
        renderType: RenderType.TEXT,
        onRowCellValueRender: (value) => {
          return truncate(value, 20);
        },
      },
      {
        key: "comment",
        headerName: "Comment",
        fieldName: "comment",
        renderType: RenderType.TEXT,
        onRowCellValueRender: (value) => {
          return capitalize(value.replace(/_/g, " "));
        },
      },
      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        renderType: RenderType.TEXT,
        onRowCellRender: (value, row) => {
          const status = value;
          if (!status || status.value === "ACTIVE") {
            return (
              <Chip
                label={`${status.value}`}
                color="error"
                size="medium"
                sx={{ fontSize: "15px" }}
              />
            );
          } else {
            return (
              <Chip
                label={`${status.value}`}
                color="success"
                size="medium"
                sx={{ fontSize: "15px" }}
              />
            );
          }
        },
      },
    ],
    items: rsaList,
    onPageChange: (newPageNumber: number) => {
      setTablePagination({ ...tablePagination, pageNumber: newPageNumber });
    },
    onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
      setTablePagination({
        pageNumber: pageNumber,
        rowsInPerPage: rowsPerPage,
      });
    },
  };

  return (
    <PageContainer
      title="RSA Users List"
      description="this is Basic Table page"
    >
      <Breadcrumb title="RSA Users List" items={BCrumb} />
      <DataTable {...rsaTableProps} />
      {openView && (
        <ViewInfoDialog
          open={true}
          rsaData={openView}
          onClose={() => setOpenView(null)}
        />
      )}

      {openAssign && (
        <CcAssignComplaint
          open={true}
          complaint={openAssign}
          onClose={() => setOpenAssign(null)}
        />
      )}
      {openClose && (
        <CcCloseComplaint
          open={true}
          complaint={openClose}
          onClose={() => setOpenClose(null)}
        />
      )}
    </PageContainer>
  );
};
