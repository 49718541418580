// Layout and colors CONSTANTS
export const LIGHT_THEME = "LIGHT_THEME";
export const DARK_THEME = "DARK_THEME";
export const THEME_COLOR = "THEME_COLOR";
export const NAVBAR_BG = "NAVBAR_BG";
export const SIDEBAR_BG = "SIDEBAR_BG";
export const DIRECTION = "DIRECTION";
export const BLUE_THEME = "BLUE_THEME";
export const LIGHT_BLUE_THEME = "LIGHT_BLUE_THEME";
export const GREEN_THEME = "GREEN_THEME";
export const RED_THEME = "RED_THEME";
export const BLACK_THEME = "BLACK_THEME";
export const PURPLE_THEME = "PURPLE_THEME";
export const INDIGO_THEME = "INDIGO_THEME";
export const ORANGE_THEME = "ORANGE_THEME";

export const USER_LOGOUT = "USER_LOGOUT";

export const DASHBOARD_RESET = "DASHBOARD_RESET";

export const FETCH_USER_ROLES_PROGRESS = "FETCH_USER_ROLES_PROGRESS";
export const FETCH_USER_ROLES_SUCCESS = "FETCH_USER_ROLES_SUCCESS";
export const FETCH_USER_ROLES_FAILURE = "FETCH_USER_ROLES_FAILURE";
export const FETCH_FAULT_TYPE_PROGRESS = "FETCH_FAULT_TYPE_PROGRESS";
export const FETCH_FAULT_TYPE_SUCCESS = "FETCH_FAULT_TYPE_SUCCESS";
export const FETCH_FAULT_TYPE_FAILURE = "FETCH_FAULT_TYPE_FAILURE";
export const FETCH_FAULT_NAME_PROGRESS = "FETCH_FAULT_NAME_PROGRESS";
export const FETCH_FAULT_NAME_SUCCESS = "FETCH_FAULT_NAME_SUCCESS";
export const FETCH_FAULT_NAME_FAILURE = "FETCH_FAULT_NAME_FAILURE";
export const FETCH_FAULT_SUB_TYPE_PROGRESS = "FETCH_FAULT_SUB_TYPE_PROGRESS";
export const FETCH_FAULT_SUB_TYPE_SUCCESS = "FETCH_FAULT_SUB_TYPE_SUCCESS";
export const FETCH_FAULT_SUB_TYPE_FAILURE = "FETCH_FAULT_SUB_TYPE_FAILURE";
export const FETCH_MECHANIC_PROGRESS = "FETCH_MECHANIC_PROGRESS";
export const FETCH_MECHANIC_SUCCESS = "FETCH_MECHANIC_SUCCESS";
export const FETCH_MECHANIC_FAILURE = "FETCH_MECHANIC_FAILURE";
export const FETCH_STATE_PROGRESS = "FETCH_STATE_PROGRESS";
export const FETCH_STATE_SUCCESS = "FETCH_STATE_SUCCESS";
export const FETCH_STATE_FAILURE = "FETCH_STATE_FAILURE";
export const FETCH_CITY_PROGRESS = "FETCH_CITY_PROGRESS";
export const FETCH_CITY_SUCCESS = "FETCH_CITY_SUCCESS";
export const FETCH_CITY_FAILURE = "FETCH_CITY_FAILURE";
export const FETCH_PACKAGES_PROGRESS = "FETCH_PACKAGES_PROGRESS";
export const FETCH_PACKAGES_SUCCESS = "FETCH_PACKAGES_SUCCESS";
export const FETCH_PACKAGES_FAILURE = "FETCH_PACKAGES_FAILURE";
export const FETCH_MAKER_PROGRESS = "FETCH_MAKER_PROGRESS";
export const FETCH_MAKER_SUCCESS = "FETCH_MAKER_SUCCESS";
export const FETCH_MAKER_FAILURE = "FETCH_MAKER_FAILURE";
export const FETCH_DUAL_MAKER_SUCCESS = "FETCH_DUAL_MAKER_SUCCESS";
export const FETCH_DUAL_MAKER_PROGRESS = "FETCH_DUAL_MAKER_PROGRESS";
export const FETCH_DUAL_MAKER_FAILURE = "FETCH_DUAL_MAKER_FAILURE";
export const FETCH_MODEL_PROGRESS = "FETCH_MODEL_PROGRESS";
export const FETCH_MODEL_SUCCESS = "FETCH_MODEL_SUCCESS";
export const FETCH_MODEL_FAILURE = "FETCH_MODEL_FAILURE";

export const FETCH_REGISTERED_USER_PROGRESS = "FETCH_REGISTERED_USER_PROGRESS";
export const FETCH_REGISTERED_USER_SUCCESS = "FETCH_REGISTERED_USER_SUCCESS";
export const FETCH_REGISTERED_USER_FAILED = "FETCH_REGISTERED_USER_FAILED";
export const DELETE_REGISTERED_USER = "DELETE_REGISTERED_USER";

export const FETCH_USER_TRANSACTIONS_BY_DATE =
  "FETCH_USER_TRANSACTIONS_BY_DATE";
export const FETCH_MECHANIC_COMPLAINTS = "FETCH_MECHANIC_COMPLAINTS";

export const FETCH_COMPLAINTS_PROGRESS = "FETCH_COMPLAINTS_PROGRESS";
export const FETCH_COMPLAINTS_SUCCESS = "FETCH_COMPLAINTS_SUCCESS";
export const FETCH_COMPLAINTS_FAILURE = "FETCH_COMPLAINTS_FAILURE";
export const ASSIGN_MECHANIC = "ASSIGN_MECHANIC";

export const FETCH_SERVICE_PROGRESS = "FETCH_SERVICE_PROGRESS";
export const FETCH_SERVICE_SUCCESS = "FETCH_SERVICE_SUCCESS";
export const FETCH_SERVICE_FAILURE = "FETCH_SERVICE_FAILURE";

export const FETCH_PROMO_PROGRESS = "FETCH_PROMO_PROGRESS";
export const FETCH_PROMO_SUCCESS = "FETCH_PROMO_SUCCESS";
export const FETCH_PROMO_FAILURE = "FETCH_PROMO_FAILURE";

export const FETCH_PROMO_LIST_PROGRESS = "FETCH_PROMO_LIST_PROGRESS";
export const FETCH_PROMO_LIST_SUCCESS = "FETCH_PROMO_LIST_SUCCESS";
export const FETCH_PROMO_LIST_FAILURE = "FETCH_PROMO_LIST_FAILURE";

export const FETCH_SALES_LEADS_PROGRESS = "FETCH_SALES_LEADS_PROGRESS";
export const FETCH_SALES_LEADS_SUCCESS = "FETCH_SALES_LEADS_SUCCESS";
export const FETCH_SALES_LEADS_FAILURE = "FETCH_SALES_LEADS_FAILURE";

export const FETCH_SFA_PROGRESS = "FETCH_SFA_PROGRESS";
export const FETCH_SFA_SUCCESS = "FETCH_SFA_SUCCESS";
export const FETCH_SFA_FAILURE = "FETCH_SFA_FAILURE";

export const FETCH_MANAGERS_PROGRESS = "FETCH_MANAGERS_PROGRESS";
export const FETCH_MANAGERS_SUCCESS = "FETCH_MANAGERS_SUCCESS";
export const FETCH_MANAGERS_FAILURE = "FETCH_MANAGERS_FAILURE";

export const FETCH_REFERRALS_PROGRESS = "FETCH_REFFERALS_PROGRESS";
export const FETCH_REFERRALS_SUCCESS = "FETCH_REFERRALS_SUCCESS";
export const FETCH_REFERRALS_FAILURE = "FETCH_REFERRALS_FAILURE";

export const FETCH_REPORTEE_REFERRALS_PROGRESS = "FETCH_REFFERALS_PROGRESS";
export const FETCH_REPORTEE_REFERRALS_SUCCESS =
  "FETCH_REPORTEE_REFERRALS_SUCCESS";
export const FETCH_REPORTEE_REFERRALS_FAILURE = "FETCH_REFERRALS_FAILURE";

export const FETCH_BRANCH_OFFICE_PROGRESS = "FETCH_BRANCH_OFFICE_PROGRESS";
export const FETCH_BRANCH_OFFICE_SUCCESS = "FETCH_BRANCH_OFFICE_SUCCESS";
export const FETCH_BRANCH_OFFICE_FAILURE = "FETCH_BRANCH_OFFICE_FAILURE";
