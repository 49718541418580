import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useAuth,
  userRoles,
} from "../../../../../contexts/AuthProvider/AuthProvider";
import moment from "moment";
import { IUserTransaction } from "../../../../../redux/dashboard/types";
import { IStoreState } from "../../../../../redux/initialStoreState";
import {
  Avatar,
  Box,
  Grid,
  MenuItem,
  SelectChangeEvent,
  Tooltip,
  Typography,
} from "@mui/material";
import { fetchTransactionsAsync } from "../../../../../redux/dashboard/dashboardActions";
import {
  IDataTableProps,
  MobileLogoRenderType,
  RenderType,
} from "../../../../../components/Table/interfaces/IDataTableProps";
import {
  CustomSelect,
  CustomTextField,
  CustomTypography,
} from "../../../../../components/formsComponents";
import { CustomDatePicker } from "../../../../../components/formsComponents/CustomDatePicker";
import {
  CSVExportPlugin,
  RefreshPlugin,
} from "../../../../../components/Table/plugins";
import { formatDate, truncate } from "../../../../../helpers";
import { StandadCard } from "../../../../../components/Cards";
import { UserInfoView } from "../UserInfoView/UserInfoView";
import { DataTable } from "../../../../../components/Table/DataTable";
import { UserTransactionCounter } from "./UserTransactionCounter";
import {
  AspectRatio,
  AssignmentInd,
  Check,
  Download,
  ForwardToInbox,
  PendingActions,
  Timeline,
} from "@mui/icons-material";
import { ContextMenu } from "../../../../../components/ContextMenu/ContextMenu";
import { AssignReferal } from "../AssignReferal/AssignReferal";
import { MembershipCard } from "../../../../../components/MembershipCard/MembershipCard";
import { useDateFilter } from "../../../../../components/Table/hooks/useDateFilter";
import { DateFilterDropdown } from "../../../../../components/Table/components/DateFilterDropdown/DateFilterDropdown";
import { fetchReferralsAsync } from "../../../../../redux/common/commonAction";
import { api } from "../../../../../api/api";
import { IBranchOffice } from "../../../../../redux/common/types";
import { ResendConfirmationDialog } from "../../../../activeMembers/components/ResendConfirmation/ResendConfirmationDialog";
import { NoReferrel } from "../../../../noReferrel/noReferrel";
import { RSAPackageReport } from "../../../../rsaReport/RSAReport";

export const UserTransactions: React.FC = () => {
  const dispatch = useDispatch<any>();
  const user = useAuth();
  const [loading, setLoading] = React.useState(false);
  const [status, setStatus] = React.useState<"ACTIVE" | "PENDING">("ACTIVE");
  const [list, setList] = React.useState<IBranchOffice>({ result: [] });
  const { date, type, handleDateSelectChange, dateDropdownList, setDate } =
    useDateFilter();
  const [transactionsLoading, setTransactionLoading] = React.useState(false);
  const [openView, setOpenview] = React.useState<IUserTransaction | null>(null);
  const [openReferal, setOpenReferal] = React.useState<IUserTransaction | null>(
    null
  );
  const [card, setCard] = React.useState<IUserTransaction | null>(null);

  const [branchOffice, setBranchOffice] = React.useState<string>(
    user.user.office ? user.user.office : "-1"
  );
  const [totalRecords, setTotalRecords] = React.useState(0);
  const transactions = useSelector(
    (state: IStoreState) =>
      state.dashboard.userTransactions.transactionsByDate[
        date.fromDate + "-" + date.toDate + "-" + status
      ]
  );
  const [openResend, setOpenResend] = React.useState<{
    open: boolean;
    memberCode: string;
  }>({ open: false, memberCode: "" });

  const [tablePagination, setTablePagination] = React.useState({
    pageNumber: 1,
    rowsInPerPage: 25,
  });

  const [searchFilter, setSearchFilter] = React.useState("-1");
  const [searchValue, setSearchValue] = React.useState("");

  const finalTransactions = React.useMemo(() => {
    let data = transactions ? transactions : { list: [], count: 0 };
    if (branchOffice === "-1") {
      return data;
    }
    const filteredData = data.list.filter(
      (x) => x.branch_office === branchOffice
    );
    data = { ...data, list: filteredData };
    return data;
  }, [branchOffice, transactions]);

  const totalValues = React.useMemo(() => {
    const totalAmount = finalTransactions.list.reduce((prev, newValue) => {
      return prev + newValue.package_price;
    }, 0);
    return {
      sale: totalAmount
        .toLocaleString("en-IN")
        .replace(/^(\d+)(\d{2})(\d{3})$/, "$1,$2,$3"),
      transactionsCount: finalTransactions.list.length,
    };
  }, [finalTransactions]);

  React.useEffect(() => {
    dispatch(
      fetchTransactionsAsync(
        tablePagination.pageNumber,
        tablePagination.rowsInPerPage,
        date.fromDate,
        date.toDate,
        status,
        {
          onStart: () => setTransactionLoading(true),
          onSuccess: () => setTransactionLoading(false),
          onError: () => setTransactionLoading(false),
        },
        user.user.role,
        false,
        searchFilter,

        searchValue
      )
    );
    console.log("searchFilter", searchFilter);
    console.log("searchValue", searchValue);
  }, [date, status, tablePagination, searchValue]);

  const refreshList = () => {
    dispatch(
      fetchTransactionsAsync(
        tablePagination.pageNumber,
        tablePagination.rowsInPerPage,
        date.fromDate,
        date.toDate,
        status,
        {
          onStart: () => setTransactionLoading(true),
          onSuccess: () => setTransactionLoading(false),
          onError: () => setTransactionLoading(false),
        },
        user.user.role,
        false
      )
    );
  };

  const handleReferalComplete = () => {
    refreshList();
    setOpenReferal(null);
  };

  React.useEffect(() => {
    if (user.user.role === userRoles.CXO) {
      dispatch(fetchReferralsAsync());
    }
  }, [user.user.role]);

  const fetchOffice = async () => {
    if (user.user && user.user.tokens) {
      try {
        const res = await api.get(`/list-branch-offices`, {
          headers: {
            "auth-Token": user.user.tokens.access,
          },
        });
        setList(res.data);
      } catch (err: any) {
        showTopErrorSnackbar(err.response.data.msg);
      } finally {
      }
    }
  };

  React.useEffect(() => {
    fetchOffice();
  }, []);

  const userTransactionTable: IDataTableProps = {
    isPagination: true,
    isDataLoading: loading,
    totalRecords: totalRecords,
    rowsPerPageOptions: tablePagination.rowsInPerPage,
    tableCommandBarProps: {
      leftItems: {
        customPlugins: [
          {
            key: "branchOffice",
            onRender: () => (
              <CustomSelect
                sx={{ minWidth: "120px" }}
                value={branchOffice}
                onChange={(e) => setBranchOffice(e.target.value as string)}
                disabled={
                  user.user.role !== userRoles.CXO &&
                  user.user.role !== userRoles.FINANCE
                }
              >
                <MenuItem value="-1">All Offices</MenuItem>
                <MenuItem value="NO REFFERAL">No Referral</MenuItem>
                {list.result.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </CustomSelect>
            ),
          },

          {
            key: "searchFilter",
            onRender: () => (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <CustomSelect
                  sx={{ minWidth: "120px" }}
                  value={searchFilter}
                  onChange={(e) => {
                    setSearchFilter(e.target.value as string);
                    setSearchValue("");
                  }}
                >
                  <MenuItem value="-1">Search By</MenuItem>
                  <MenuItem value="membership_code">MemberShip Code</MenuItem>
                  <MenuItem value="contact_no">Contact Number</MenuItem>
                  {searchFilter !== "-1" && (
                    <MenuItem value="-1" style={{ color: "red" }}>
                      RESET
                    </MenuItem>
                  )}
                </CustomSelect>
                {searchFilter !== "-1" && (
                  <CustomTextField
                    sx={{ minWidth: "160px", marginLeft: "13px" }}
                    // placeholder={`Enter ${searchFilter === "membership_code" ? "Membership Code" : (searchFilter === "contact_no" ? "Contact Number" : "Invoice Number")}`}
                    placeholder={`Enter ${
                      searchFilter === "membership_code"
                        ? "Membership Code"
                        : "Contact Number"
                    }`}
                    value={searchValue}
                    onChange={(e: {
                      target: { value: React.SetStateAction<string> };
                    }) => setSearchValue(e.target.value)}
                  />
                )}
              </div>
            ),
          },

          {
            key: "status",
            onRender: () => (
              <CustomSelect
                sx={{ minWidth: "120px" }}
                value={status}
                onChange={(e) => setStatus(e.target.value as "ACTIVE")}
              >
                <MenuItem value="ACTIVE">ACTIVE</MenuItem>
                <MenuItem value="PENDING">PENDING</MenuItem>
              </CustomSelect>
            ),
          },
          {
            key: "byDate",
            onRender: () => (
              <DateFilterDropdown
                value={type}
                options={dateDropdownList}
                onChange={handleDateSelectChange}
              />
            ),
          },
          {
            key: "fromDate",
            onRender: () =>
              type === "custom" ? (
                <CustomDatePicker
                  sx={{ minWidth: "142px" }}
                  value={date.fromDate}
                  onChange={(newValue) => {
                    const newDate = moment(newValue).format("YYYY-MM-DD");
                    setDate({ ...date, fromDate: newDate });
                  }}
                />
              ) : (
                <></>
              ),
          },
          {
            key: "toDate",
            onRender: () =>
              type === "custom" ? (
                <CustomDatePicker
                  sx={{ minWidth: "142px" }}
                  value={date.toDate}
                  onChange={(newValue) => {
                    const newDate = moment(newValue).format("YYYY-MM-DD");
                    setDate({ ...date, toDate: newDate });
                  }}
                />
              ) : (
                <></>
              ),
          },
          // {
          //   key: "searchFilter",
          //   onRender: () => (
          //     <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          //       <CustomSelect
          //         sx={{ minWidth: "120px" }}
          //         value={searchFilter}
          //         onChange={(e) => {
          //           setSearchFilter(e.target.value as string);
          //           setSearchValue("");
          //         }}
          //       >
          //         <MenuItem value="-1">Search By</MenuItem>
          //         <MenuItem value="membership_code">MemberShip Code</MenuItem>
          //         <MenuItem value="contact_no">Contact Number</MenuItem>
          //         <MenuItem value="vehicle_no">Vehicle Number</MenuItem>
          //         {searchFilter !== "-1" && (
          //           <MenuItem value="-1" style={{ color: 'red' }}>RESET</MenuItem>
          //         )}
          //       </CustomSelect>
          //       {searchFilter !== "-1" && (
          //         <CustomTextField
          //           sx={{ minWidth: "160px", marginLeft: "13px" }}
          //           placeholder={`Enter ${searchFilter === "membership_code" ? "Membership Code" : (searchFilter === "contact_no" ? "Contact Number" : "Vehicle Number")}`}
          //           value={searchValue}
          //           onChange={(e: { target: { value: React.SetStateAction<string>; }; }) => setSearchValue(e.target.value)}
          //         />
          //       )}
          //     </div>
          //   ),
          // },
          {
            key: "refresh",
            onRender: () => <RefreshPlugin onClick={refreshList} />,
          },
          {
            key: "csvExport",
            onRender: (columns, items) => (
              <CSVExportPlugin
                columns={columns}
                items={items}
                filePrefixName="Report"
              />
            ),
          },
        ],
      },
      rightItems: {
        plugins: {
          searchField: {
            searchKeys: [
              "user_prospect_id",
              "user_name",
              "contact_no",
              "email_id",
              "state",
              "city",
              "area",
              "address",
              "vehicle_no",
              "make_name",
              "model_name",
              "package_name",
              "package_price",
              "comment",
              "promo_code",
              "referral_code",
              "status",
            ],
            items: finalTransactions.list,
          },
        },
        customPlugins: [],
      },
    },
    mobileLogo: {
      type: MobileLogoRenderType.reactNode,
      onMobileLogoRender: (data: any) => {
        return (
          <Avatar
            sx={{
              width: 45,
              height: 45,
              color: "#fff",
              ml: "-8px",
              mr: 2,
            }}
          ></Avatar>
        );
      },
    },
    columns: [
      {
        key: "actions",
        headerName: "Actions",
        fieldName: "",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row) => {
          let menuOptions = [
            {
              icon: <AspectRatio />,
              label: "See Details",
              onClick: () => setOpenview(row as IUserTransaction),
            },
            {
              icon: <Timeline />,
              label: "See Timeline",
              onClick: () =>
                window.open(
                  "/user-timeline/" +
                    (row as IUserTransaction).user_prospect_id,
                  "_blank"
                ),
            },
            {
              icon: <ForwardToInbox />,
              label: "Resend Confirmation",
              onClick: () =>
                setOpenResend({
                  open: true,
                  memberCode: row.membership_code,
                }),
            },
          ];
          menuOptions =
            user.user.role === userRoles.SALES_TEAM_LEAD
              ? menuOptions
              : [
                  ...menuOptions,
                  {
                    icon: <AssignmentInd />,
                    label: "Assign Referral",
                    onClick: () => setOpenReferal(row as IUserTransaction),
                  },
                ];

          if ((row as IUserTransaction).membership_code) {
            menuOptions.push({
              icon: <Download />,
              label: "Download Card",
              onClick: () => {
                setCard(row as IUserTransaction);
              },
            });
          }

          return <ContextMenu menuOptions={menuOptions}></ContextMenu>;
        },
      },
      {
        key: "userId",
        headerName: "Id",
        fieldName: "user_prospect_id",
        isHidden: true,
        renderType: RenderType.TEXT,
      },
      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        exportCellWidth: 25,
        onExportRender: (value) => {
          return value.value;
        },
        renderType: RenderType.TEXT,
        onRowCellRender: (value, row) => {
          return (
            <Box>
              {value.value === "ACTIVE" ? (
                <Tooltip title="ACTIVE">
                  <Check color="success" />
                </Tooltip>
              ) : (
                <Tooltip title="Pending">
                  <PendingActions color="warning" />
                </Tooltip>
              )}
            </Box>
          );
        },
      },
      {
        key: "dateColumn",
        headerName: status === "PENDING" ? "Start Date" : "Payment Date",
        fieldName: status === "PENDING" ? "insert_dt" : "payment_date",
        exportCellWidth: 25,
        renderType: RenderType.DATE,
      },
      {
        key: "vehicle_no",
        headerName: "Vehicle No.",
        fieldName: "vehicle_no",
        renderType: RenderType.TEXT,
      },
      {
        key: "name",
        headerName: "Name",
        fieldName: "user_name",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },
      {
        key: "email",
        headerName: "Email",
        fieldName: "email_id",
        isHidden: true,
        exportCellWidth: 30,
        renderType: RenderType.TEXT,
      },

      {
        key: "contact_no",
        headerName: "Contact",
        fieldName: "contact_no",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },

      {
        key: "membership_code",
        headerName: "Membership Id",
        fieldName: "membership_code",
        exportCellWidth: 25,
        isHidden: true,
        renderType: RenderType.TEXT,
      },
      {
        key: "state",
        headerName: "State",
        fieldName: "state",
        isHidden: true,
        renderType: RenderType.TEXT,
      },
      {
        key: "city",
        headerName: "City",
        fieldName: "city",
        isHidden: true,
        renderType: RenderType.TEXT,
      },
      {
        key: "area",
        headerName: "Area",
        fieldName: "area",
        isHidden: true,
        renderType: RenderType.TEXT,
      },
      {
        key: "address",
        headerName: "Address",
        fieldName: "address",
        isHidden: true,
        exportCellWidth: 35,
        renderType: RenderType.TEXT,
      },
      {
        key: "comment",
        headerName: "Comment",
        fieldName: "comment",
        isHidden: true,
        exportCellWidth: 40,
        renderType: RenderType.TEXT,
      },

      {
        key: "vehicle_no",
        headerName: "Vehicle No.",
        fieldName: "vehicle_no",
        isHidden: true,
        exportCellWidth: 20,
        renderType: RenderType.TEXT,
      },
      {
        key: "make_name",
        headerName: "Brand",
        isHidden: true,
        fieldName: "make_name",
        renderType: RenderType.TEXT,
      },
      {
        key: "model_name",
        headerName: "Model",
        fieldName: "model_name",
        isHidden: true,
        renderType: RenderType.TEXT,
      },
      {
        key: "packageId",
        headerName: "Package Id",
        fieldName: "package_id",
        isHidden: true,
        renderType: RenderType.TEXT,
      },
      {
        key: "package_name",
        headerName: "Package",
        fieldName: "package_name",
        renderType: RenderType.TEXT,
        exportCellWidth: 35,
        onRowCellValueRender: (value) => {
          return truncate(value, 10);
        },
      },

      {
        key: "package_price",
        headerName: "Price",
        fieldName: "package_price",
        exportCellWidth: 20,
        renderType: RenderType.TEXT,
      },
      {
        key: "gstAmount",
        headerName: "GST Amount",
        fieldName: "gst_amount",
        isHidden: true,
        renderType: RenderType.TEXT,
      },
      {
        key: "gstin",
        headerName: "gstin",
        fieldName: "gstin",
        isHidden: true,
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },

      {
        key: "packageperiod",
        headerName: "Package Period",
        fieldName: "package_period",
        isHidden: true,
        exportCellWidth: 20,
        renderType: RenderType.TEXT,
      },
      {
        key: "package_services",
        headerName: "Package Services",
        fieldName: "package_services",
        isHidden: true,
        renderType: RenderType.TEXT,
      },
      {
        key: "promo_code",
        headerName: "Promo Code",
        fieldName: "promo_code",
        isHidden: true,
        renderType: RenderType.TEXT,
      },
      {
        key: "referral_code",
        headerName: "Referral Code",
        fieldName: "referral_code",
        isHidden: true,
        renderType: RenderType.TEXT,
      },

      {
        key: "payment_mode",
        headerName: "Payment Mode",
        fieldName: "payment_mode",
        renderType: RenderType.TEXT,
        exportCellWidth: 15,
      },

      {
        key: "branch_office",
        headerName: "Branch Office",
        fieldName: "branch_office",
        renderType: RenderType.TEXT,
        exportCellWidth: 25,
      },
    ],
    items: finalTransactions.list,
    onPageChange: (newPageNumber: number) => {
      setTablePagination({ ...tablePagination, pageNumber: newPageNumber });
    },
    onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
      setTablePagination({
        pageNumber: pageNumber,
        rowsInPerPage: rowsPerPage,
      });
    },
  };

  return (
    <>
      <StandadCard heading="RSA Package Report" sx={{ marginBottom: 6 }}>
        <RSAPackageReport />
      </StandadCard>
      {/* <MembershipCard ref={ref} /> */}
      <Grid container spacing={3}>
        {/* ------------------------- row 1 ------------------------- */}
        <Grid item xs={12} lg={12}>
          <UserTransactionCounter />
        </Grid>
        <Grid item xs={12} lg={12}>
          <StandadCard heading="Users Transactions">
            {/* <CustomTypography variant="body2" color="primary.main">
              The number of {status.toLowerCase()} transactions for the selected
              period : {finalTransactions.count}
            </CustomTypography> */}
            <Box
              sx={{
                padding: 2,
                borderRadius: 1,
                m: 2,
                backgroundColor:
                  status === "ACTIVE" ? "background.default" : "warning.light",
              }}
            >
              <CustomTypography variant="body1" sx={{ mb: 1 }}>
                Based on the filter values you have selected from the table,
                here are the results:
              </CustomTypography>

              <Grid container spacing={0}>
                <Grid item xs={12} md={2}>
                  <CustomTypography variant="h5" fontWeight={"bold"}>
                    TOTAL SALES
                  </CustomTypography>
                  <CustomTypography variant="h3" fontWeight={"bold"}>
                    ₹ {totalValues.sale}
                  </CustomTypography>
                </Grid>
                <Grid item xs={12} md={3}>
                  <CustomTypography variant="h5" fontWeight={"bold"}>
                    TOTAL TRANSACTIONS
                  </CustomTypography>
                  <CustomTypography variant="h3" fontWeight={"bold"}>
                    {totalValues.transactionsCount}
                  </CustomTypography>
                </Grid>
              </Grid>
            </Box>
            <DataTable {...userTransactionTable} />
          </StandadCard>
        </Grid>
      </Grid>
      {openView && (
        <UserInfoView
          open={true}
          transaction={openView}
          onClose={() => setOpenview(null)}
        />
      )}
      {openReferal && (
        <AssignReferal
          open={true}
          data={openReferal}
          onClose={() => setOpenReferal(null)}
          onComplete={handleReferalComplete}
        />
      )}
      {card && (
        <MembershipCard
          download={true}
          cardInfo={{
            customerName: card.user_name,
            membershipCode: card.membership_code || "",
            planName: card.package_name,
            vehicleNumber: card.vehicle_no,
          }}
          onDownloadComplete={() => setCard(null)}
        />
      )}
      {openResend.open && (
        <ResendConfirmationDialog
          open={true}
          membershipCode={openResend.memberCode}
          onClose={() =>
            setOpenResend({
              open: false,
              memberCode: "",
            })
          }
        />
      )}
    </>
  );
};
function showTopErrorSnackbar(msg: any) {
  throw new Error("Function not implemented.");
}
