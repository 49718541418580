import React from "react";
import { Dialog } from "../../../../components/Dialogs/Dialog";
import { Grid } from "@mui/material";
import { StandadCard } from "../../../../components/Cards";
import { StandardCellList } from "../../../../components/Standards";
import { formatDate } from "../../../../helpers";
import { IViewManualActivationProps } from "./interfaces/IViewManualActivation";

export const ViewManualActivation: React.FC<IViewManualActivationProps> = (props) => {
  const { open, activation, onClose } = props;

  return (
    <Dialog
      open={open}
      title="Activation Information"
      onClose={onClose}
      size="md"
      actions={[
        { type: "button", label: "Close", variant: "text", onClick: onClose },
      ]}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} lg={12}>
          <StandadCard heading="About Activation" sx={{ mb: 2 }}>
            <StandardCellList
              colSize={3}
              list={[
                {
                  heading: "Prospect ID",
                  data: {
                    type: "text",
                    value: "# " + activation.user_prospect_id,
                  },
                },
                {
                  heading: "Name",
                  data: { type: "text", value: activation.user_name },
                },
                {
                  heading: "Contact Number",
                  data: {
                    type: "text",
                    value: activation.contact_no,
                  },
                },
                {
                  heading: "Email ID",
                  data: {
                    type: "text",
                    value: activation.email_id,
                  },
                },
                {
                  heading: "Date",
                  data: {
                    type: "text",
                    value: formatDate(activation.insert_dt),
                  },
                },
                {
                  heading: "State",
                  data: { type: "text", value: activation.state },
                },
                {
                  heading: "City",
                  data: { type: "text", value: activation.city },
                },
                {
                  heading: "Area",
                  data: { type: "text", value: activation.area },
                },
                {
                  heading: "Address",
                  data: { type: "text", value: activation.address },
                },
                {
                    heading: "Payment Mode",
                    data: { type: "text", value: activation.payment_mode },
                  },

                {
                  heading: "Status",
                  data: {
                    type: "text",
                    value: activation.status,
                    color:
                    activation.status === "ACTIVE"
                        ? "succes.main"
                        : "error.main",
                  },
                },
              ]}
            />
          </StandadCard>
        </Grid>
        <Grid item xs={12} lg={8}>
          <StandadCard heading="Plan Information" sx={{ mb: 2 }}>
            <StandardCellList
              colSize={4}
              list={[
                {
                  heading: "package Name",
                  data: {
                    type: "text",
                    value: activation.package_name,
                  },
                },

                {
                  heading: "Package Price",
                  data: { type: "text", value: activation.package_price },
                },
                {
                  heading: "Package Period",
                  data: { type: "text", value: activation.package_period },
                },
                {
                  heading: "Package Services",
                  data: { type: "text", value: activation.package_services },
                },

                {
                  heading: "Promo Code",
                  data: { type: "text", value: activation.promo_code },
                },
                {
                  heading: "Referral Code",
                  data: { type: "text", value: activation.referral_code },
                },
              ]}
            />
          </StandadCard>
        </Grid>
        <Grid item xs={12} lg={4}>
          <StandadCard heading="vehicle Information" sx={{ mb: 2 }}>
            <StandardCellList
              colSize={6}
              list={[
                {
                  heading: "Vehicle Number",
                  data: {
                    type: "text",
                    value: activation.vehicle_no,
                  },
                },

                {
                  heading: "Brand",
                  data: { type: "text", value: activation.make_name },
                },

                {
                  heading: "Modal",
                  data: { type: "text", value: activation.model_name },
                },
                {
                  heading: "Membership Code",
                  data: { type: "text", value: activation.membership_code, color: 'error.main' },
                },
              ]}
            />
          </StandadCard>
        </Grid>
     
      </Grid>
    </Dialog>
  );
};
