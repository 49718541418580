import produce from "immer";
import { RegisteredUsersActions } from ".";
import { LoadState } from "../../constants/enums";
import {
  DELETE_REGISTERED_USER,
  FETCH_REGISTERED_USER_PROGRESS,
  FETCH_REGISTERED_USER_SUCCESS,
  FETCH_USER_ROLES_FAILURE,
} from "../constants";
import { IStoreState } from "../initialStoreState";
import { initialRegisteredUsersState } from "./types";

export const registeredUsersReducer = (
  state: IStoreState["registeredUsers"] = initialRegisteredUsersState,
  action: RegisteredUsersActions
) => {
  switch (action.type) {
    case FETCH_REGISTERED_USER_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loadState = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_REGISTERED_USER_SUCCESS: {
      const newState = produce(state, (draftState) => {
        draftState.users = action.payload.users;
        draftState.loadState = LoadState.Loaded;
      });
      return newState;
    }
    case FETCH_USER_ROLES_FAILURE: {
      const newState = produce(state, (draftState) => {
        draftState.loadState = LoadState.Failed;
      });
      return newState;
    }
    case DELETE_REGISTERED_USER: {
      const { userId } = action.payload;
      const newState = produce(state, (draftState) => {
        const index = draftState.users.findIndex(x=>x.backoffice_users_id === userId);
        if(index >-1){
          draftState.users.splice(index,1);
        }
      });
      return newState;
    }
    default:
      return state;
  }
};
