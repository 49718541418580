import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { IComplaint } from "../../constants/commonTypes";
import { userRoles } from "../../contexts/AuthProvider/AuthProvider";
import { getUserAuth } from "../../helpers";
import {
  FETCH_USER_TRANSACTIONS_BY_DATE,
  FETCH_MECHANIC_COMPLAINTS,
} from "../constants";
import { IStoreState } from "../initialStoreState";
import { IUserTransaction } from "./types";

export const fetchTransactions = (
  key: string,
  list: IUserTransaction[],
  count: number
) => action(FETCH_USER_TRANSACTIONS_BY_DATE, { key, list, count });

export const fetchTransactionsAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    fromDate: string,
    toDate: string,
    status: "ACTIVE" | "PENDING",
    callBacks: {
      onStart: () => void;
      onSuccess: () => void;
      onError: (errMessage: string) => void;
    },
    role: keyof typeof userRoles,
    isNoRefereal: boolean,
    searchFilter?: string,
    searchValue?: string,
    refresh?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    // console.log(isNoRefereal, "no refereal");
    try {
      const key = fromDate + "-" + toDate + "-" + status;

      const token = getUserAuth();
      if (token) {
        callBacks.onStart();
        let url = `/dashboard-list-of-users-activated-or-pending?page=${pageNumber}&size=${rowsInPerPage}`;
        let filter;
        // if(searchFilter){
        //    filter =`&${searchFilter}=${searchValue}`;
        // }
        if (searchFilter && searchValue && searchFilter !== "-1") {
          // url = `/dashboard-list-of-users-activated-or-pending?&${searchFilter}=${searchValue.trim()}`;
          url = `/dashboard-list-of-users-activated-or-pending?page=${pageNumber}&size=${rowsInPerPage}&${searchFilter}=${searchValue.trim()}`;
        }

        // console.log("url", url);
        // console.log("searchFilter", searchFilter);
        // console.log("searchValue", searchValue);

        if (role === userRoles.SALES_TEAM_LEAD) {
          url = `/dashboard-list-of-leads-under-sales-team-lead?page=${pageNumber}&size=${rowsInPerPage}`;
          //  url += filter;
        }
        if (role === userRoles.SALES_TEAM_LEAD && isNoRefereal) {
          url = `/dashboard-list-of-users-activated-or-pending?page=${pageNumber}&size=${rowsInPerPage}`;
        }
        // if(searchFilter === "-1"){
        url = `${url}&from_date=${fromDate}&to_date=${toDate}&operation=LIST&status_v=${status}`;
        // }

        const res = await api.get(url, {
          headers: {
            "auth-Token": token,
          },
        });

        dispatch(fetchTransactions(key, res.data, res.data.length));
        callBacks.onSuccess();
      }
    } catch (err) {
      callBacks.onError("");
    }
  };

export const fetchMechanicComplaints = (
  key: string,
  list: IComplaint[],
  count: number
) => action(FETCH_MECHANIC_COMPLAINTS, { key, list, count });

export const fetchMechanicComplaintsAsync =
  (
    fromDate: string,
    toDate: string,
    status: "OPEN" | "CLOSED",

    callBacks: {
      onStart: () => void;
      onSuccess: () => void;
      onError: (errMessage: string) => void;
    },
    refresh?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const key = fromDate + "-" + toDate + "-" + status;

      const token = getUserAuth();
      if (token) {
        callBacks.onStart();
        const res = await api.get(
          `/dashboard-complaint-assign-to-mechanic?from_date_v=${fromDate}&to_date_v=${toDate}&operation_name_v=LIST&status_v=${status}`,
          {
            headers: {
              "auth-Token": token,
            },
          }
        );

        dispatch(fetchMechanicComplaints(key, res.data, res.data.length));
        callBacks.onSuccess();
      }
    } catch (err) {
      callBacks.onError("");
    }
  };
