import { LoadState } from "../../constants/enums";

export interface IRegisteredUser {
  backoffice_users_id: number;
  user_name: string;
  email_id: string;
  password: string;
  role: string;
  role_id: number;
  reporting_manager_id: number | null;
  reports_to: string;
  entry_dt: string;
  last_login: string;
  referral_code: string;
  expire_on: string;
  status: string;
  insert_dt: string;
}

export interface IRegisteredUsers {
  users: IRegisteredUser[];
  loadState: LoadState;
}

export const initialRegisteredUsersState: IRegisteredUsers = {
  users: [],
  loadState: LoadState.NotLoaded,
};
