import { IComplaint } from "../../constants/commonTypes";
import { LoadState } from "../../constants/enums";


export interface IComplaintsState {
    complaints: IComplaint[];
    loadState: LoadState;
}

export const initialComplaintsState: IComplaintsState = {
  complaints: [],
  loadState: LoadState.NotLoaded,
}