import { Theme, Components } from "@mui/material";
import { borderColor } from "./colors";

export const components: Components = {
  MuiCssBaseline: {
    styleOverrides: {
      "*": {
        boxSizing: "border-box",
      },
      html: {
        height: "100%",
        width: "100%",
      },
      body: {
        height: "100%",
        margin: 0,
        padding: 0,
        color: "rgb(38, 38, 38)",
      },
      "#root": {
        height: "100%",
      },
      "*[dir='rtl'] .buyNowImg": {
        transform: "scaleX(-1)",
      },

      ".buyNowImg": {
        position: "absolute",
        right: "-44px",
        top: "-18px",
        width: "143px",
        height: "175px",
      },
      ".MuiCardHeader-action": {
        alignSelf: "center !important",
      },
      ".scrollbar-container": {
        borderRight: "0 !important",
      },
      "*[dir='rtl'] .welcomebg:before": {
        backgroundPosition: "top -24px left -9px !important",
      },
    },
  },
  MuiContainer: {
    styleOverrides: {
      root: {
        paddingLeft: "15px !important",
        paddingRight: "15px !important",
        maxWidth: "1600px",
      },
    },
  },
  MuiToolbar: {
    styleOverrides: {
      root: {
        minHeight: "60px !important",
      },
    },
  },

  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: "none",
        boxShadow: "none",
        fontSize: "15px",
        "&:hover": {
          boxShadow: "none",
        },
      },
    },
  },

  MuiListItem: {
    styleOverrides: {
      root: {
        borderRadius: "0px",
      },
    },
  },

  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: "4px",
        padding: "14px",
        margin: "15px",
        border: "1px solid " + borderColor,
        boxShadow: "none",
      },
    },
  },

  MuiListItemIcon: {
    styleOverrides: {
      root: {
        minWidth: "30px",
      },
    },
  },

  // MuiGridItem: {
  //   styleOverrides: {
  //     root: {
  //       paddingTop: "30px",
  //       paddingLeft: "30px !important",
  //     },
  //   },
  // },
  MuiLinearProgress: {
    styleOverrides: {
      root: {
        backgroundColor: "#ecf0f2",
        borderRadius: "6px",
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        borderRadius: "0",
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        fontWeight: "500",
        fontSize: "0.75rem",
      },
    },
  },
  MuiTab:{
    styleOverrides: {
      root: {
        minHeight: 'unset',
      }
    }
  },
  MuiAlert: {
    styleOverrides: {
      root: {
        "&.MuiAlert-standardError": {
          backgroundColor: "rgb(253, 237, 237)",
        },
      },
    },
  },
};
