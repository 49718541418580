import { useFormik } from "formik";
import React from "react";
import { api } from "../../api/api";
import { Dialog } from "../../components/Dialogs/Dialog";
import { CustomTextField, CustomTypography } from "../../components/formsComponents";
import { getUserAuth } from "../../helpers";
import { useAuth } from "../AuthProvider/AuthProvider";
import { useMessagesPopup } from "../MessagesPopupProvider/MessagesPopupProvider";
import { ICustomerCareDialogProps } from "./interfaces/ICustomerCareDialogProps";
import { DialogActions, Button } from "@mui/material";

export const AgentDialog: React.FC<ICustomerCareDialogProps> = (props) => {
  const { open, onClose } = props;
  const callCenterName = localStorage.getItem("callCenterName") || "";
  const [loading, setLoading] = React.useState(false);
  const { showTopErrorSnackbar } = useMessagesPopup();
  const { logout } = useAuth();

  const { values, errors, handleChange, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: {
        name: callCenterName,
      },
      validate: (values) => {
        const errors: any = {};
        if (values.name === "") {
          errors.name = "*You can't leave this empty";
        }
        return errors;
      },
      onSubmit: async (values) => {
        try {
          setLoading(true);
          if (values.name !== callCenterName) {
            localStorage.setItem("callCenterName", values.name);
          }
          const token = getUserAuth();
          if (token) {
            await api.post(
              "/bo-agent-name-log",
              {
                agent_name_v: values.name,
              },
              {
                headers: {
                  "auth-Token": token,
                },
              }
            );
            onClose(values.name);
          }
        } catch (err: any) {
          showTopErrorSnackbar(err.response.data.msg);
        } finally {
          setLoading(false);
        }
      },
    });

  React.useEffect(() => {
    if (callCenterName) {
      setFieldValue("name", callCenterName, true);
    }
  }, []);

  return (
    <>
      <Dialog
        open={open}
        size="xs"
        title="Log in to Continue"
        onClose={() => {}}
        hideCloseIcon
        contentWrappedWithForm={{
          onSubmit: handleSubmit,
        }}
        actions={[
          {
            label: "Logout",
            type: "button",
            variant: "text",
            onClick: () => logout(),
          },
          {
            label: loading ? "Please wait..." : "Save",
            type: "submit",
            disabled: loading,
            variant: "contained",
          },
        ]}
      >
        <CustomTypography sx={{mb: 2}} variant="body1">Welcome back! Please enter your name to continue the account. If you would like to change your previous name, feel free to edit it below.</CustomTypography>
        <CustomTextField
          id="name"
          variant="outlined"
          size="small"
          fullWidth
          value={values.name}
          placeholder='Enter your name'
          onChange={handleChange}
          error={errors.name ? true : false}
          helperText={errors.name}
        />
        {/* <DialogActions>
          <Button
            type="button"
            variant="text"
            color="primary"
            onClick={() => logout()}
          >
            Logout
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={loading}
            color="primary"
          >
            {loading ? "Please wait..." : "Save"}
          </Button>
        </DialogActions> */}
      </Dialog>
    </>
  );
};
