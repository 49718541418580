import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { store } from "./redux/store";
import { AuthProvider } from "./contexts/AuthProvider/AuthProvider";
import { MessagesPopupProvider } from "./contexts/MessagesPopupProvider/MessagesPopupProvider";
import { LinearProgress } from "@mui/material";
import { AgentProvider } from "./contexts/CustomerCareContext/AgentProvider";
import { SocketProvider } from "./contexts/SocketContext/SocketContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <AuthProvider>
        <SocketProvider>
          <MessagesPopupProvider>
            <AgentProvider>
              <Suspense fallback={<LinearProgress />}>
                <App />
              </Suspense>
            </AgentProvider>
          </MessagesPopupProvider>
        </SocketProvider>
      </AuthProvider>
    </Provider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
