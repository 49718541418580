import { Box } from "@mui/material";
import React from "react";
import { ISuccessDialogProps } from "./interfaces/IDialogProps";
import { CheckCircleOutline } from "@mui/icons-material";
import { useDialogStyles } from "./useDialogStyles";
import { Dialog } from "./Dialog";
import { CustomTypography } from "../formsComponents";

export const SuccessDialog: React.FC<ISuccessDialogProps> = (props) => {
  const { open, content, onClose } = props;

  const classes = useDialogStyles();
  return (
    <>
      <Dialog
        open={open}
        title="Success"
        onClose={onClose}
        size="sm"
        actions={[
          {
            type: "button",
            variant: "contained",
            label: "Close",
            onClick: onClose,
          },
        ]}
      >
        <Box display={"flex"} justifyContent={"center"} sx={{ mb: 2 }}>
          <CheckCircleOutline className={classes.successIcon} />
        </Box>
        <CustomTypography variant="h6" textAlign={"center"} sx={{ mb: 2 }}>
          {content}
        </CustomTypography>
      </Dialog>
    </>
  );
};
