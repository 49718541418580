import { IServices } from "../../constants/commonTypes";
import { LoadState } from "../../constants/enums";

export interface IServiceState {
  services: IServices[];
  loadState: LoadState;
}

export const initialServicesState: IServiceState = {
  services: [],
  loadState: LoadState.NotLoaded,
};
