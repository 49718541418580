import produce from "immer";
import { DashboardActions } from ".";
import {
  FETCH_MECHANIC_COMPLAINTS,
  FETCH_USER_TRANSACTIONS_BY_DATE,
} from "../constants";
import { IStoreState } from "../initialStoreState";
import { initialSowDashboard } from "./types";

export const sowDashboardReducer = (
  state: IStoreState["sowDashboard"] = initialSowDashboard,
  action: DashboardActions
) => {
  switch (action.type) {
    case FETCH_USER_TRANSACTIONS_BY_DATE: {
      const { key, list, count } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.sowUserTransactions.transactionsByDate[key] = {
          count: count,
          list: list,
        };
      });
      return newState;
    }
    case FETCH_MECHANIC_COMPLAINTS: {
      const { key, list, count } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.complaints.compaintsByDate[key] = {
          count: count,
          list: list,
        };
      });
      return newState;
    }

    default:
      return state;
  }
};
