import React from "react";
import { StandadCard } from "../../../../../components/Cards";
import { Dialog } from "../../../../../components/Dialogs/Dialog";
import { StandardCellList } from "../../../../../components/Standards";
import {
  IUserInfoViewProps,
} from "./interfaces/IUserInfoView";
import { Grid, Tab, Tabs, Box } from "@mui/material";
import { formatDate } from "../../../../../helpers";
import { Check, DirectionsCar, Feed, MobileFriendly, PendingActions } from "@mui/icons-material";
import { TabPanel } from "../../../../../components/Tabs/TabPanel";
import { a11yProps } from "../../../../../components/Tabs/utils";
import { api } from "../../../../../api/api";
import { LoadState } from "../../../../../constants/enums";
import { useAuth } from "../../../../../contexts/AuthProvider/AuthProvider";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../../../../components/Table/DataTable";
import { CustomTypography } from "../../../../../components/formsComponents";
import { IUserMembership } from "../../../../../constants/commonTypes";

import { ContextMenu } from "../../../../../components/ContextMenu/ContextMenu";
import { AspectRatio } from "@mui/icons-material";
import { ICommandBarCustomPlugin } from "../../../../../components/Table/interfaces/IDataTableProps";
import { ViewCustomerConnection } from "../../../../../components/CustomerTrackTable/components/ViewCustomerConnection/ViewCustomerConnection";

export const UserInfoView: React.FC<IUserInfoViewProps> = (props) => {
  const { open, transaction, onClose } = props;
  const auth = useAuth();
  const [loading, setLoading] = React.useState<LoadState>(LoadState.NotLoaded);
  const [tabValue, setTabValue] = React.useState(0);
  const [byMobile, setByMobile] = React.useState<IUserMembership[]>([]);
  const [byVechicle, setByVechicle] = React.useState<IUserMembership[]>([]);
  const [openView, setOpenView] =
    React.useState<ICommandBarCustomPlugin | null>(null);

  React.useEffect(() => {
    (async () => {
      if (auth.user.isLogin && auth.user.tokens) {
        try {
          setLoading(LoadState.InProgress);
          const res1 = await api.get(
            `/check-vehicle-phone-exist?phone_number=${transaction.contact_no}`,
            {
              headers: {
                "auth-Token": auth.user.tokens.access,
              },
            }
          );
          const res2 = await api.get(
            `/check-vehicle-phone-exist?vehicle_number=${transaction.vehicle_no}`,
            {
              headers: {
                "auth-Token": auth.user.tokens.access,
              },
            }
          );
          const mobileData = res1.data.data;
          if (Array.isArray(mobileData)) {
            setByMobile([...mobileData]);
          } else {
            setByMobile([]);
          }
          const VechicleData = res2.data.data;
          if (Array.isArray(VechicleData)) {
            setByVechicle([...VechicleData]);
          } else {
            setByVechicle([]);
          }

          setLoading(LoadState.Loaded);
        } catch (err) {
          setLoading(LoadState.Failed);
        }
      }
    })();
  }, []);

  

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const mobileTable: IDataTableProps = {
    isDataLoading: loading === LoadState.InProgress,

    columns: [
      {
        key: "membership_start_date",
        headerName: "Date",
        fieldName: "membership_start_date",
        renderType: RenderType.DATE,
      },
      {
        key: "vehicle_number",
        headerName: "Vechicle Number",
        fieldName: "vehicle_number",
        renderType: RenderType.TEXT,
      },
      {
        key: "maker_name",
        headerName: "Brand",
        fieldName: "maker_name",
        renderType: RenderType.TEXT,
      },

      {
        key: "model_name",
        headerName: "Model",
        fieldName: "model_name",
        renderType: RenderType.TEXT,
      },
      // {
      //   key: "comment",
      //   headerName: "Comment",
      //   fieldName: "comment",
      //   renderType: RenderType.TEXT,
      // },
      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value) => {
          return (
            <Box>
            {value.value === 'ACTIVE' ? <Check color='success'/> : <PendingActions color='warning'/>}
           </Box>
          );
        },
      },
            {
              key: "actions",
              headerName: "Actions",
              fieldName: "",
              renderType: RenderType.CUSTOM_RENDER,
              onRowCellRender: (value, row) => {
                return (
                  <ContextMenu
                    menuOptions={[
                      {
                        icon: <AspectRatio />,
                        label: "See Details",
                        onClick: () => setOpenView(row),
                      },
                    ]}
                  ></ContextMenu>
                );
              },
            },
    ],
    items: byMobile,
  };

  const vechicleTable: IDataTableProps = {
    isDataLoading: loading === LoadState.InProgress,

    columns: [
      {
        key: "membership_start_date",
        headerName: "Date",
        fieldName: "membership_start_date",
        renderType: RenderType.DATE,
      },
      {
        key: "contact_number",
        headerName: "Mobile Number",
        fieldName: "contact_number",
        renderType: RenderType.TEXT,
      },
      {
        key: "maker_name",
        headerName: "Brand",
        fieldName: "maker_name",
        renderType: RenderType.TEXT,
      },

      {
        key: "model_name",
        headerName: "Model",
        fieldName: "model_name",
        renderType: RenderType.TEXT,
      },
      // {
      //   key: "comment",
      //   headerName: "Comment",
      //   fieldName: "comment",
      //   renderType: RenderType.TEXT,
      // },
      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value) => {
          return (
           <Box>
            {value.value === 'ACTIVE' ? <Check color='success'/> : <PendingActions color='warning'/>}
           </Box>
          );
        },
      },
      {
        key: "actions",
        headerName: "Actions",
        fieldName: "",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row) => {
          return (
            <ContextMenu
              menuOptions={[
                {
                  icon: <AspectRatio />,
                  label: "See Details",
                  onClick: () => setOpenView(row),
                },
              ]}
            ></ContextMenu>
          );
        },
      },
    ],
    items: byVechicle,
  };

  return (
    <Dialog
      open={open}
      title="Transaction Details"
      onClose={onClose}
      fullScreen
      contentSx={{ paddingTop: 0, paddingBottom: 0 }}
      size="md"
      actions={[
        { type: "button", label: "Close", variant: "text", onClick: onClose },
      ]}
    >
      <Box sx={{ borderBottom: 1, marginBottom: 2, borderColor: "divider" }}>
        <Tabs value={tabValue} onChange={handleChange}>
          <Tab
            icon={<Feed />}
            iconPosition="start"
            label="User Profile"
            {...a11yProps(0)}
          />
          <Tab
            sx={{ minHeight: "50px" }}
            icon={<MobileFriendly />}
            iconPosition="start"
            label="By Mobile"
            {...a11yProps(1)}
          />
          <Tab
            icon={<DirectionsCar />}
            iconPosition="start"
            label="By Vechicle Number"
            {...a11yProps(2)}
          />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={0}>
        <Grid container spacing={1}>
          <Grid item xs={12} lg={12}>
            <StandadCard
              heading="About User"
              sx={{ mb: 2 }}
              rightHeading={
                "Transaction Date : " + formatDate(transaction.insert_dt)
              }
            >
              <StandardCellList
                colSize={4}
                list={[
                  {
                    heading: "User prospect Id",
                    data: {
                      type: "text",
                      value: "# " + transaction.user_prospect_id,
                    },
                  },
                  {
                    heading: "User Name",
                    data: { type: "text", value: transaction.user_name },
                  },
                  {
                    heading: "Email",
                    data: {
                      type: "text",
                      value: transaction.email_id,
                      color: "primary.main",
                    },
                  },
                  {
                    heading: "Contact Number",
                    data: {
                      type: "text",
                      value: transaction.contact_no,
                      color: "error.main",
                    },
                  },
                  {
                    heading: "State",
                    data: { type: "text", value: transaction.state },
                  },
                  {
                    heading: "City",
                    data: { type: "text", value: transaction.city },
                  },
                  {
                    heading: "Area",
                    data: { type: "text", value: transaction.area },
                  },
                  {
                    heading: "Address",
                    data: { type: "text", value: transaction.address },
                  },
                  {
                    heading: "Branch Office",
                    data: { type: "text", value: transaction.branch_office },
                  },
                  {
                    heading: "Comment",
                    data: {
                      type: "text",
                      value: transaction.comment,
                      color: "warning.main",
                    },
                  },
                ]}
              />
            </StandadCard>
          </Grid>
          <Grid item xs={12} lg={8}>
            <StandadCard heading="Package" sx={{ mb: 2 }}>
              <StandardCellList
                colSize={4}
                list={[
                  {
                    heading: "Package Id",
                    data: {
                      type: "text",
                      value: "# " + transaction.package_id,
                    },
                  },
                  {
                    heading: "Name",
                    data: { type: "text", value: transaction.package_name },
                  },
                  {
                    heading: "Price",
                    data: { type: "text", value: transaction.package_price },
                  },
                  {
                    heading: "Payment Mode",
                    data: { type: "text", value: transaction.payment_mode },
                  },
                  {
                    heading: "Last Update Date",
                    data: { type: "text", value: formatDate(transaction.insert_dt ), },
                  }, 
                  {
                    heading: "GST",
                    data: {
                      type: "text",
                      value:
                        transaction.gst_amount +
                        `(${transaction.gst_percentage}%)`,
                    },
                  },
                  {
                    heading: "Period",
                    data: {
                      type: "text",
                      value: transaction.package_period,
                      color: "primary.main",
                    },
                  },
                  {
                    heading: "Services",
                    data: { type: "text", value: transaction.package_services },
                  },
                  {
                    heading: "Promo Code",
                    data: { type: "text", value: transaction.promo_code },
                  },
                  {
                    heading: "Referral Code",
                    data: { type: "text", value: transaction.referral_code },
                  },
                  {
                    heading: "Status",
                    data: {
                      type: "text",
                      value: transaction.status,
                      color:
                        transaction.status === "ACTIVE"
                          ? "success.main"
                          : "error.main",
                    },
                  },
                ]}
              />
            </StandadCard>
          </Grid>
          <Grid item xs={12} lg={4}>
            <StandadCard heading="Vechicle" sx={{ mb: 2 }}>
              <StandardCellList
                colSize={6}
                list={[
                  {
                    heading: "RTO",
                    data: {
                      type: "text",
                      value: transaction.vehicle_no,
                    },
                  },
                  {
                    heading: "Brand",
                    data: { type: "text", value: transaction.make_name },
                  },
                  {
                    heading: "Model",
                    data: { type: "text", value: transaction.model_name },
                  },
                  {
                    heading: "Membership Code",
                    data: { type: "text", value: transaction.membership_code },
                  },
                ]}
              />
            </StandadCard>
          </Grid>
        </Grid> 
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Grid container spacing={1}>
          <Grid item xs={12} lg={12}>
            <StandadCard
              heading="Transaction Done By User Using Mobile Number"
              rightHeading={`Contact Number : ` + transaction.contact_no}
              sx={{ mb: 2 }}
            >
              {byMobile.length > 0 && loading === LoadState.Loaded && (
                <DataTable {...mobileTable} />
              )}

              {byMobile.length === 0 && loading === LoadState.Loaded && (
                <CustomTypography variant="body1" sx={{color: 'error.main'}}>
                  No transactions found with this 
                  contact number {transaction.contact_no}.
                </CustomTypography>
              )}
            </StandadCard>
          </Grid>
        </Grid>
        {openView && (
        <ViewCustomerConnection
          open={true}
          connection={openView}
          onClose={() => setOpenView(null)}
        />
      )}
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <Grid container spacing={1}>
          <Grid item xs={12} lg={12}>
            <StandadCard
              heading="Transaction Done By User Using Vechicle Number"
              rightHeading={`Vechicle Number : ` + transaction.vehicle_no}
              sx={{ mb: 2 }}
            >
              {byMobile.length > 0 && loading === LoadState.Loaded && (
                <DataTable {...vechicleTable} />
              )}

              {byMobile.length === 0 && loading === LoadState.Loaded && (
                <CustomTypography variant="body1" sx={{color: 'error.main'}}>
                  No transactions found with this 
                  vechicle number {transaction.vehicle_no}.
                </CustomTypography>
              )}
            </StandadCard>
          </Grid>
        </Grid> 
        {openView && (
        <ViewCustomerConnection
          open={true}
          connection={openView}
          onClose={() => setOpenView(null)}
        />
      )}
      </TabPanel>
    </Dialog>
  );
};
