import React, { useState } from 'react';
import {
    Button,
    Container,
    Grid,
    MenuItem,
    Paper,
    TextField,
    Typography,
} from '@mui/material';
import { useMessagesPopup } from '../../../../contexts/MessagesPopupProvider/MessagesPopupProvider';
import { useAuth } from '../../../../contexts/AuthProvider/AuthProvider';
import { api } from '../../../../api/api';
import { useNavigate, useParams } from 'react-router-dom';
import { IEditMechanicDetails } from '../../../AddNewMechanic/Interface/IEditMechanicDetails';

export const EditComplaint: React.FC = () => {

    const { id } = useParams();

    const [formData, setFormData] = useState({

        username_v: "",
        email_id_v: "",
        password_v: "",
        branch_office_v: "",
        state_v: "",
        city_v: "",
        location_v: "",
        address_v: "",
        pin_v: "",
        latitude_v: 0,
        longitude_v: 0,
        payout_type_v: "",
        nodal_v: "",
        agreed_amount_v: 0

    });

    const resetForm = () => {
        setFormData({
            username_v: "",
            email_id_v: "",
            password_v: "",
            branch_office_v: "",
            state_v: "",
            city_v: "",
            location_v: "",
            address_v: "",
            pin_v: "",
            latitude_v: 0,
            longitude_v: 0,
            payout_type_v: "",
            nodal_v: "",
            agreed_amount_v: 0
        });
    };
    const auth = useAuth();
    const [pageLoading, setPageLoading] = React.useState(false);
    const { showTopSuccessSnackbar, showTopErrorSnackbar } = useMessagesPopup();
    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }; 

    const navigate = useNavigate();
    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        // Perform validation here (e.g., mobile number and email format)
        if (!isValidEmail(formData.email_id_v)) {
            alert('Please enter a valid email.');
            return;
        }
        try {
            if (auth.user && auth.user.tokens) {
                setPageLoading(true);
                await api.post(
                    "/create-mechanic-by-control-room-lead",
                    {
                        ...formData,

                    },
                    {
                        headers: {
                            "auth-Token": auth.user.tokens.access,
                        },
                    }
                );
            }
            showTopSuccessSnackbar("Channel Registered Successfully.");
            navigate("/crl-assign");
        } catch (err: any) {
            showTopErrorSnackbar(err.response.msg);
        } finally {
            setPageLoading(false);
        }
    }
    const isValidEmail = (email: string) => /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(email);


    const fetchMechanicDetails = async () => {
        if (auth.user && auth.user.tokens) {
            try {
                setPageLoading(true);
                const res = await api.get("/get-mechanic-list?mechanic_id_v=" + id, {
                    headers: {
                        "auth-Token": auth.user.tokens.access,
                    },
                });
                const result = res.data;
                if (result.length > 0) {
                    const mechanicData = result[0] as IEditMechanicDetails;
                    setFormData({
                        username_v: mechanicData.user_name,
                        email_id_v: mechanicData.email_id,
                        password_v: mechanicData.password,
                        branch_office_v: mechanicData.branch_office,
                        state_v: mechanicData.state,
                        city_v: mechanicData.city,
                        location_v: mechanicData.location,
                        address_v: mechanicData.address,
                        pin_v: mechanicData.pin,
                        latitude_v: 0,
                        longitude_v: 0,
                        payout_type_v: mechanicData.payout_type,
                        nodal_v: mechanicData.nodal,
                        agreed_amount_v: mechanicData.agreed_amount

                    });
                } else {
                    setError(
                        "Oops! We couldn't find any records associated with your mechanic id at the moment. Please ensure that the provided information is accurate and try again."
                    );
                }
            } catch (err: any) {
                showTopErrorSnackbar(err.response.data.msg);
            } finally {
                setPageLoading(false);
            }
        }
    };

    React.useEffect(() => {
        if (id) {
            fetchMechanicDetails();
        }
    }, [id]);

    return (
        <Container maxWidth="lg">
            <Typography variant="h4" gutterBottom>
                Complaints Edit Form
            </Typography>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    {/* Business Details */}
                    <Grid item xs={12}>
                        <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
                            <Typography variant="h5" gutterBottom>
                                Complaint Details
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Name of Mechanic"
                                        fullWidth
                                        name="username_v"
                                        value={formData.username_v}
                                        onChange={handleChange}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Email ID"
                                        fullWidth
                                        name="email_id_v"
                                        value={formData.email_id_v}
                                        onChange={handleChange}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Decided Amount"
                                        fullWidth
                                        name="agreed_amount_v"
                                        value={formData.agreed_amount_v}
                                        onChange={handleChange}

                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Payout Type"
                                        fullWidth
                                        name="payout_type_v"
                                        value={formData.payout_type_v}
                                        onChange={handleChange}
                                        required
                                        select
                                    >
                                        <MenuItem value="Per Service">Per Service</MenuItem>
                                        <MenuItem value="Monthly Based">Salary Based</MenuItem>
                                    </TextField>
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField
                                        label="Password"
                                        fullWidth
                                        name="password_v"
                                        type="password"
                                        value={formData.password_v}
                                        onChange={handleChange}

                                    // error={errors.new_password ? true : false}
                                    // helperText={errors.new_password}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        label="Confirm Password"
                                        fullWidth
                                        name=""
                                        type="password"
                                        value={formData.password_v}
                                        onChange={handleChange}

                                    // error={errors.new_password ? true : false}
                                    // helperText={errors.new_password}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    {/* Contact Details */}
                    <Grid item xs={12}>
                        <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
                            <Typography variant="h5" gutterBottom>
                                Nodal Details
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Address"
                                        fullWidth
                                        name="address_v"
                                        value={formData.address_v}
                                        onChange={handleChange}

                                    // inputProps={{ maxLength: 10 }}
                                    />
                                </Grid>
                                <Grid item sm={6}>
                                    <TextField
                                        label="Location"
                                        fullWidth
                                        name="location_v"
                                        value={formData.location_v}
                                        onChange={handleChange}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField
                                        label="City"
                                        fullWidth
                                        name="city_v"
                                        value={formData.city_v}
                                        onChange={handleChange}

                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="State"
                                        fullWidth
                                        name="state_v"
                                        value={formData.state_v}
                                        onChange={handleChange}

                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="pin code"
                                        fullWidth
                                        name="pin_v"
                                        value={formData.pin_v}
                                        onChange={handleChange}

                                    />
                                </Grid>
                                {/* <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Nodal Name"
                                        fullWidth
                                        name="nodal_v"
                                        value={formData.nodal_v}
                                        onChange={handleChange}
                                        required
                                        select
                                    >
                                        <MenuItem value="">Select Nodal Name</MenuItem>
                                        {nodalNames.map((nodal) => (
                                            <MenuItem key={nodal} value={nodal}>
                                                {nodal}
                                            </MenuItem>
                                        ))}
                                    </TextField> 
                                     </Grid> */}

                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
                <Grid container sx={{ mt: 4 }}>
                   
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                        >
                            Submit
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={4} />
                
            </form>
        </Container>
    );
};
function setError(arg0: string) {
    throw new Error('Function not implemented.');
}

