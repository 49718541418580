import React from "react";
import { Dialog } from "../../../../../components/Dialogs/Dialog";
import { Grid, Typography } from "@mui/material";
import { StandadCard } from "../../../../../components/Cards";
import { StandardCellList } from "../../../../../components/Standards";
import { api } from "../../../../../api/api";
import { useAuth } from "../../../../../contexts/AuthProvider/AuthProvider";
import { IVehicleListProps, IViewCustomerConnection } from "../../../../../components/CustomerTrackTable/components/ViewCustomerConnection/IViewCustomerConnection";
import { formatDate } from "../../../../../helpers";
import { IVehicleList } from "../interfaces/IComplaintLog";

export const GetVehicle: React.FC<IVehicleListProps> = (
  props
) => {
  const { connection, updateVehicleCount  } = props;

  const allowedPackageIds = [1, 21, 22, 23];
  const [vehicleList, setVehicleList] = React.useState<IVehicleList[]>([]);
  const auth = useAuth();

  React.useEffect(() => {
    // Define the fetch function
    const fetchVehicleList = async () => {
      if (auth.user && auth.user.tokens) {
        try {
          const res = await api.get(`/get-family-added-vehicles?membership_code_v=${connection.membership_code}`, {
            headers: {
              "auth-Token": auth.user.tokens.access,
            },
          });
          setVehicleList(res.data.result);
          updateVehicleCount(res.data.result.length);
        } catch (err: any) {
          // Handle error if needed
        }
      }
    };

    if (allowedPackageIds.includes(connection.package_id)) {
      fetchVehicleList();
    }
  }, [connection.package_id, auth.user, connection.membership_code, updateVehicleCount]);

  return (
    <Grid container spacing={2}>
      {allowedPackageIds.includes(connection.package_id) && vehicleList.map((vehicle, index) => (
        <Grid item xs={12} lg={6} key={index}>
          <StandadCard heading={`Secondary Vehicle ${index + 2}`}> 
            <StandardCellList
              colSize={6}
              list={[
                {
                  heading: "Vehicle Number",
                  data: {
                    type: "text",
                    value: (
                      <Typography style={{ color: 'red' }}>{vehicle.vehicle_no}</Typography>
                    ),
                  },
                },
                {
                  heading: "Brand",
                  data: { type: "text", value: vehicle.make_name },
                },
                {
                  heading: "Model",
                  data: { type: "text", value: vehicle.model_name },
                },
                {
                  heading: "Registration Year",
                  data: {
                    type: "text",
                    value: vehicle.registration_year,
                  },
                },
                {
                  heading: "Status",
                  data: {
                    type: "text",
                    value: vehicle.status,
                  },
                },
              ]}
            />
          </StandadCard>
        </Grid>
      ))}
    </Grid>
  );
};
