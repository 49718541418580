import React from "react";
import { IStandardCellProps } from "./interfaces/IStandards";
import { Box } from "@mui/material";
import { CustomTypography } from "../formsComponents";

export const StandardCardCell: React.FC<IStandardCellProps> = (props) => {
  const { heading, data, isHide } = props;
  if(isHide){
    return <></>;
  }
  return (
    <Box>
      <CustomTypography variant="body1" color="rgb(140, 140, 140) !important" textTransform={'capitalize'}> 
        {heading}
      </CustomTypography>
      <CustomTypography variant="body2"  sx={{ mb: 1,fontSize: '0.85rem !important',wordBreak: 'break-word', color: data.color }}>
        {data.value && data.value !== 'null' && data.value !== 'undefined' ? data.value : '--'}
      </CustomTypography>
    </Box>
  );
};
