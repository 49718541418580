import React from "react";
import { styled } from "@mui/material/styles";
import { Select, SelectProps } from "@mui/material";

export const CustomSelect = styled((props: SelectProps) => (
  <Select {...props} />
))(({ theme }) => ({
  "& .MuiSelect-select": {
    color: "#767e89",
    padding: '9px 13px',
    fontSize: '0.87rem',
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: 0,
    borderColor: `${
      theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.12)" : "#dee3e9"
    }`,
  },
  "& .MuiSelect-select::-webkit-input-placeholder": {
    color: "#767e89",
    opacity: "1",
  },
}));
