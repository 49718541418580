import moment from 'moment';

export const convertDate = (targetDate: string) => {
    let date = new Date(targetDate);
   
    date = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    console.log(`${date.getFullYear()}-0${date.getMonth()}-${date.getDate()}`)
    console.log(date)
    
  return `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`
  };
  

export const formatDate = (date: string) => {
  return  moment(date).format('DD/MM/YY');
}


export const formatDateWithTime = (date: string) => {
  return  moment.utc(date).format('DD/MM/YY hh:mm:ss A');
}