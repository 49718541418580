import produce from "immer";
import { DashboardActions } from ".";
import { LoadState } from "../../constants/enums";
import { FETCH_MECHANIC_COMPLAINTS, FETCH_USER_TRANSACTIONS_BY_DATE } from "../constants";
import { IStoreState } from "../initialStoreState";
import { initialDashboard } from "./types";

export const dashboardReducer = (
  state: IStoreState["dashboard"] = initialDashboard,
  action: DashboardActions
) => {
  switch (action.type) {
    case FETCH_USER_TRANSACTIONS_BY_DATE: {
      const { key, list, count } = action.payload;
      const newState = produce(state, (draftState) => {
        
        draftState.userTransactions.transactionsByDate[key] = {
          count: count,
          list: list,
        };
      });
      return newState;
    }
    case FETCH_MECHANIC_COMPLAINTS: {
      const { key, list, count } = action.payload;
      const newState = produce(state, (draftState) => {
        
        draftState.complaints.compaintsByDate[key] = {
          count: count,
          list: list,
        };
      });
      return newState;
    }


    default:
      return state;
  }
};
