import { Avatar, Box, useMediaQuery } from "@mui/material";
import React from "react";
import { api } from "../../api/api";
import {
  IDataTableProps,
  MobileLogoRenderType,
  RenderType,
} from "../../components/Table/interfaces/IDataTableProps";
import { useAuth } from "../../contexts/AuthProvider/AuthProvider";
import { useMessagesPopup } from "../../contexts/MessagesPopupProvider/MessagesPopupProvider";

import { RSADataTable } from "../../components/Table/RsaDataTable";
import { useSocket } from "../../contexts/SocketContext/SocketContext";
import { IRSAReport } from "./interface/IRSAReport";

export const RSAPackageReport: React.FC = () => {
  const BCrumb = [
    {
      to: "/",
      title: "cxo-main",
    },
    {
      title: "rsa-report-list",
    },
  ];

  const [rsaList, setRSAList] = React.useState<IRSAReport[]>([]);
  const [loading, setLoading] = React.useState(false);

  const [status, setStatus] = React.useState("ACTIVE");
  const { user } = useAuth();
  const { showTopErrorSnackbar, showTopSuccessSnackbar, showSuccessMessage } =
    useMessagesPopup();
  const [tablePagination, setTablePagination] = React.useState({
    pageNumber: 1,
    rowsInPerPage: 50,
  });
  const [totalRecords, setTotalRecords] = React.useState(0);
  const { socket } = useSocket();

  const mobile = useMediaQuery("(max-width: 600px)");

  const fetchRSARecordist = async () => {
    try {
      if (user.isLogin && user.tokens && user.tokens.access) {
        setLoading(true);
        const response = await api.get(`/get-rsa-report`, {
          headers: {
            "auth-Token": user.tokens.access,
          },
        });
        setRSAList(response.data);
        // setTotalRecords(response.data.total.totalDataLength);
      }
    } catch (error: any) {
      showTopErrorSnackbar(error.response.data.msg);
    } finally {
      setLoading(false);
    }
  };

  const totalMTDLength = rsaList.reduce(
    (sum, item) => sum + item.month_latest_user_prospect_length,
    0
  );
  const totalMTDAmount = rsaList.reduce(
    (sum, item) => sum + item.month_latest_user_prospect_package_price,
    0
  );
  const totalFTDLength = rsaList.reduce(
    (sum, item) => sum + item.today_latest_user_prospect_length,
    0
  );
  const totalFTDAmount = rsaList.reduce(
    (sum, item) => sum + item.today_latest_user_prospect_package_price,
    0
  );
  const totalOkhlaLength = rsaList.reduce(
    (sum, item) => sum + item.today_referral_length,
    0
  );
  const totalOkhlaAmount = rsaList.reduce(
    (sum, item) => sum + item.today_referral_package_price,
    0
  );
  const totalNoRefLength = rsaList.reduce(
    (sum, item) => sum + item.today_no_referral_length,
    0
  );
  const totalNoRefAmount = rsaList.reduce(
    (sum, item) => sum + item.today_no_referral_package_price,
    0
  );

  console.log("totalNoReferralLength", totalMTDLength);

  React.useEffect(() => {
    fetchRSARecordist();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tablePagination, status]);

  //socket setup
  // React.useEffect(() => {
  //   if (socket) {
  //     socket.on("newRsaRecord", () => {
  //       const audio = new Audio(alarmAudio);

  //       audio.play();
  //       showTopSuccessSnackbar("A new RSA request detected!");
  //       showSuccessMessage(<Box>New Record Found</Box>);

  //       fetchRSAUsersList();
  //     });
  //   }

  //   return () => {
  //     if (socket) {
  //       socket.off("newRsaRecord"); // Clean up the socket event listener
  //     }
  //   };
  // }, [socket, showTopSuccessSnackbar]);

  //   const fetchAllData = async (): Promise<ICSVDataFormat[]> => {
  //     const res = await api.get(`/get-quick-rsa-list`);
  //     const data: ICSVDataFormat[] = res.data.result;
  //     return data;
  //   };

  const rsaTableProps: IDataTableProps = {
    isPagination: true,
    isDataLoading: loading,
    totalRecords: totalRecords,
    rowsPerPageOptions: tablePagination.rowsInPerPage,
    tableCommandBarProps: {
      leftItems: {
        customPlugins: [
          //   {
          //     key: "status",
          //     onRender: () => (
          //       <CustomSelect
          //         sx={{ minWidth: "120px" }}
          //         value={status}
          //         onChange={(e) => setStatus(e.target.value as string)}
          //       >
          //         <MenuItem value="ACTIVE">ACTIVE</MenuItem>
          //         <MenuItem value="CLOSED">CLOSED</MenuItem>
          //       </CustomSelect>
          //     ),
          //   },
          //   {
          //     key: "refresh",
          //     onRender: () => (
          //       <RefreshPlugin
          //         onClick={() => {
          //           fetchRSARecordist();
          //         }}
          //       />
          //     ),
          //   },
          //   {
          //     key: "csvExport",
          //     onRender: (columns, items) => (
          //       <CSVExportPluginonPagination
          //         columns={[
          //           { columnName: "RSA Help ID", fieldName: "quick_rsa_help_id" },
          //           { columnName: "Vehicle No.", fieldName: "vehicle_no" },
          //           { columnName: "Mobile Number", fieldName: "mobile_no" },
          //           { columnName: "Assigned To", fieldName: "assign_to_name" },
          //           { columnName: "Address", fieldName: "address", width: 30 },
          //           { columnName: "Status", fieldName: "status" },
          //         ]}
          //         onExportButtonClick={fetchAllData}
          //         filePrefixName="Report"
          //       />
          //     ),
          //   },
        ],
      },
      rightItems: {
        // plugins: {
        //   searchField: {
        //     searchKeys: [
        //       "quick_rsa_help_id",
        //       "address",
        //       "mobile_no",
        //       "vehicle_no",
        //       "status",
        //       "comment",
        //       "insert_dt",
        //     ],
        //     items: rsaList,
        //   },
        // },
      },
    },
    mobileLogo: {
      type: MobileLogoRenderType.reactNode,
      onMobileLogoRender: (data: any) => {
        return (
          <Avatar
            sx={{
              width: 45,
              height: 45,
              color: "#fff",
              ml: "-8px",
              mr: 2,
            }}
          ></Avatar>
        );
      },
    },
    columns: [
      {
        key: "package_name",
        headerName: "",
        fieldName: "package_name",
        renderType: RenderType.TEXT,
      },
      {
        key: "today_no_referral_length",
        headerName: mobile ? "No Referral Unit" : "Unit",
        fieldName: "today_no_referral_length",
        renderType: RenderType.TEXT,
      },

      {
        key: "today_no_referral_package_price",
        headerName: mobile ? "No Referral Amount" : "Amount",
        fieldName: "today_no_referral_package_price",
        renderType: RenderType.TEXT,
      },
      {
        key: "today_referral_length",
        headerName: mobile ? "Okhla Unit" : "Unit",
        fieldName: "today_referral_length",
        renderType: RenderType.TEXT,
      },

      {
        key: "today_referral_package_price",
        headerName: mobile ? "Okhla Amount" : "Amount",
        fieldName: "today_referral_package_price",
        renderType: RenderType.TEXT,
      },
      {
        key: "today_latest_user_prospect_length",
        headerName: mobile ? "FTD Unit" : "Unit",
        fieldName: "today_latest_user_prospect_length",
        renderType: RenderType.TEXT,
      },

      {
        key: "today_latest_user_prospect_package_price",
        headerName: mobile ? "FTD Amount" : "Amount",
        fieldName: "today_latest_user_prospect_package_price",
        renderType: RenderType.TEXT,
      },
      {
        key: "month_latest_user_prospect_length",
        headerName: mobile ? "MTD Unit" : "Unit",
        fieldName: "month_latest_user_prospect_length",
        renderType: RenderType.TEXT,
      },

      {
        key: "month_latest_user_prospect_package_price",
        headerName: mobile ? "MTD Amount" : "Amount",
        fieldName: "month_latest_user_prospect_package_price",
        renderType: RenderType.TEXT,
      },
    ],
    items: rsaList,
    // onPageChange: (newPageNumber: number) => {
    //   setTablePagination({ ...tablePagination, pageNumber: newPageNumber });
    // },
    // onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
    //   setTablePagination({
    //     pageNumber: pageNumber,
    //     rowsInPerPage: rowsPerPage,
    //   });
    // },
  };

  return (
    <Box>
      <RSADataTable
        {...rsaTableProps}
        norefUnit={totalNoRefLength}
        norefAmount={totalNoRefAmount}
        totalMTDLength={totalMTDLength}
        totalMTDAmount={totalMTDAmount}
        totalFTDLength={totalFTDLength}
        totalFTDAmount={totalFTDAmount}
        totalOkhlaLength={totalOkhlaLength}
        totalOkhlaAmount={totalOkhlaAmount}
      />
    </Box>
  );
};
