import LinearProgress from '@mui/material/LinearProgress/LinearProgress';
import React, { Suspense } from 'react';

// project imports

// ===========================|| LOADABLE - LAZY LOADING ||=========================== //

const Loadable = (Component: any) => (props: any) =>
  (
    <Suspense fallback={<LinearProgress  />}>
      <Component {...props} />
    </Suspense>
  );

export default Loadable;
