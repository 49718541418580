import React from "react";
import { IAssignReferalProps } from "./interfaces/IAssignReferalProps";
import { useAuth } from "../../../../../contexts/AuthProvider/AuthProvider";
import { useMessagesPopup } from "../../../../../contexts/MessagesPopupProvider/MessagesPopupProvider";
import { api } from "../../../../../api/api";
import { Dialog } from "../../../../../components/Dialogs/Dialog";

import { MenuItem } from "@mui/material";
import {  useSelector } from "react-redux";
import { IStoreState } from "../../../../../redux/initialStoreState";
import { LoadState } from "../../../../../constants/enums";
import { useFormik } from "formik";
import { CustomFormLabel, CustomSelect } from "../../../../../components/formsComponents";


export const AssignReferal: React.FC<IAssignReferalProps> = (props) => {
  const { open, data, onClose } = props;

  const [loading, setLoading] = React.useState(false);
  const auth = useAuth();
  const { showTopSuccessSnackbar, showTopErrorSnackbar } = useMessagesPopup();

  const { list, loadState } = useSelector(
    (storeState: IStoreState) => storeState.common.referrals
  );

  const { values, errors, handleChange, handleSubmit } = useFormik({
    initialValues: {
      user_prospect_id_v: data.user_prospect_id,
      referral_code_v: "-1",
    },
    validate: (values) => {
      const errors: any = {};
      if (values.referral_code_v === "-1") {
        errors.referral_code_v = "*You can't leave this empty";
      }
      return errors;
    },
    onSubmit: async (values) => {
      try {
        setLoading(true);

        if (auth.user.tokens && auth.user.tokens.access) {
          await api.post("/bo-assign-referral-code", values, {
            headers: {
              "auth-Token": auth.user.tokens.access,
            },
          });
          showTopSuccessSnackbar("Referral is assigned successfully.");
          props.onComplete();
        }
      } catch (err: any) {
        showTopErrorSnackbar(err.response.data.result);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <Dialog
      open={open}
      size="xs"
      title="Assign Referral"
      onClose={props.onClose}
      contentWrappedWithForm={{
        onSubmit: handleSubmit,
      }}
      actions={[
        {
          label: loading ? "Please wait..." : "Assign",
          type: "submit",
          disabled: loading,
          variant: "contained",
        },
      ]}
    >
      <CustomFormLabel sx={{ mt: 0 }}>Referral Code</CustomFormLabel>
       <CustomSelect
        name="referral_code_v"
        value={values.referral_code_v}
        fullWidth
        size="small"
        onChange={handleChange}
        error={errors.referral_code_v ? true : false}
      >
        {(loadState === LoadState.InProgress
          ? [{ value: -1, label: "Loading..." }]
          : [{ value: -1, label: "Select Refer" }, ...list]
        ).map((item) => {
          return (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          );
        })}
      </CustomSelect>
    </Dialog>
  );
  
};
