import { useFormik } from "formik";
import React from "react";
import { IResendConfirmationDialogProps } from "./interfaces/IResendConfirmation";
import { useMessagesPopup } from "../../../../contexts/MessagesPopupProvider/MessagesPopupProvider";
import { useAuth } from "../../../../contexts/AuthProvider/AuthProvider";
import { api } from "../../../../api/api";
import { Dialog } from "../../../../components/Dialogs/Dialog";
import {
  CustomTextField,
  CustomTypography,
} from "../../../../components/formsComponents";

export const ResendConfirmationDialog: React.FC<
  IResendConfirmationDialogProps
> = (props) => {
  const { open, onClose,membershipCode } = props;
  const [loading, setLoading] = React.useState(false);
  const { showSuccessMessage, showTopErrorSnackbar } = useMessagesPopup();
  const auth = useAuth();

  const { values, errors, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        member_code_v: membershipCode,
      },
      validate: (values) => {
        const errors: any = {};
        if (values.member_code_v === "") {
          errors.member_code_v = "*You can't leave this empty";
        }
        return errors;
      },
      onSubmit: async (values) => {
        try {
          setLoading(true);

          if (auth.user.tokens && auth.user.tokens.access) {
            await api.get(
              `/resend-confirmation?member_code_v=${values.member_code_v}`,

              {
                headers: {
                  "auth-Token": auth.user.tokens.access,
                },
              }
            );
            showSuccessMessage("Membership confirmation send successfully.");
            onClose();
          }
        } catch (err: any) {
          showTopErrorSnackbar(err.response.data.msg);
        } finally {
          setLoading(false);
        }
      },
    });

  return (
    <>
      <Dialog
        open={open}
        size="xs"
        title="Resend Confirmation"
        onClose={onClose}
        contentWrappedWithForm={{
          onSubmit: handleSubmit,
        }}
        actions={[
          {
            label: "Resend",
            type: "submit",
            disabled: loading,
            variant: "contained",
          },
        ]}
      >
        <CustomTypography sx={{ mb: 2 }} variant="body1">
          Membership Code
        </CustomTypography>
        <CustomTextField
          id="member_code_v"
          variant="outlined"
          size="small"
          fullWidth
          value={values.member_code_v}
          placeholder="Enter member code"
          onChange={handleChange}
          error={errors.member_code_v ? true : false}
          helperText={errors.member_code_v}
        />
        {/* <DialogActions>
          <Button
            type="button"
            variant="text"
            color="primary"
            onClick={() => logout()}
          >
            Logout
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={loading}
            color="primary"
          >
            {loading ? "Please wait..." : "Save"}
          </Button>
        </DialogActions> */}
      </Dialog>
    </>
  );
};
