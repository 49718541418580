import { Box, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import React from "react";
import { CustomTypography } from "../formsComponents";
import { IStandardCardProps } from "./interfaces/ICardsProps";

export const StandadCard: React.FC<IStandardCardProps> = (props) => {
  const { heading, rightHeading, sx } = props;

  const isNode = React.isValidElement(rightHeading);

  return (
    <Card
      sx={{
        margin: 0,
        borderRadius: "2px",
        padding: '20px',
        ...sx,
      }}
    >
      <Box display="flex" justifyContent={"space-between"} alignItems="center">
       {heading &&  <Typography variant="body1" fontWeight={"400"}>
          {heading}
        </Typography>}
        {isNode && <Box>{rightHeading}</Box>}
        {!isNode && (
          <Typography variant="body1" fontWeight={"400"}>
            {rightHeading}
          </Typography>
        )}
      </Box>
    {heading &&   <Divider sx={{ mt: 2, mb: 2 }} />}
      {props.children}
    </Card>
  );
};
