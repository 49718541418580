import produce from "immer";
import { CommonActions } from ".";
import { LoadState } from "../../constants/enums";
import {
  FETCH_CITY_FAILURE,
  FETCH_CITY_PROGRESS,
  FETCH_CITY_SUCCESS,
  FETCH_FAULT_NAME_FAILURE,
  FETCH_FAULT_NAME_PROGRESS,
  FETCH_FAULT_NAME_SUCCESS,
  FETCH_FAULT_SUB_TYPE_FAILURE,
  FETCH_FAULT_SUB_TYPE_PROGRESS,
  FETCH_FAULT_SUB_TYPE_SUCCESS,
  FETCH_FAULT_TYPE_FAILURE,
  FETCH_FAULT_TYPE_PROGRESS,
  FETCH_FAULT_TYPE_SUCCESS,
  FETCH_MAKER_FAILURE,
  FETCH_MAKER_PROGRESS,
  FETCH_MAKER_SUCCESS,
  FETCH_MANAGERS_FAILURE,
  FETCH_MANAGERS_PROGRESS,
  FETCH_MANAGERS_SUCCESS,
  FETCH_MECHANIC_FAILURE,
  FETCH_MECHANIC_PROGRESS,
  FETCH_MECHANIC_SUCCESS,
  FETCH_MODEL_FAILURE,
  FETCH_MODEL_PROGRESS,
  FETCH_MODEL_SUCCESS,
  FETCH_PACKAGES_FAILURE,
  FETCH_PACKAGES_PROGRESS,
  FETCH_PACKAGES_SUCCESS,
  FETCH_SALES_LEADS_FAILURE,
  FETCH_SALES_LEADS_PROGRESS,
  FETCH_SALES_LEADS_SUCCESS,
  FETCH_SFA_FAILURE,
  FETCH_SFA_PROGRESS,
  FETCH_SFA_SUCCESS,
  FETCH_STATE_FAILURE,
  FETCH_STATE_PROGRESS,
  FETCH_STATE_SUCCESS,
  FETCH_USER_ROLES_FAILURE,
  FETCH_USER_ROLES_PROGRESS,
  FETCH_USER_ROLES_SUCCESS,
  FETCH_REFERRALS_FAILURE,
  FETCH_REFERRALS_PROGRESS,
  FETCH_REFERRALS_SUCCESS,
  FETCH_REPORTEE_REFERRALS_FAILURE,
  FETCH_REPORTEE_REFERRALS_PROGRESS,
  FETCH_REPORTEE_REFERRALS_SUCCESS,
  FETCH_DUAL_MAKER_PROGRESS,
  FETCH_DUAL_MAKER_SUCCESS,
  FETCH_DUAL_MAKER_FAILURE,
} from "../constants";
import { IStoreState } from "../initialStoreState";
import { initialCommonState } from "./types";

export const commonReducer = (
  state: IStoreState["common"] = initialCommonState,
  action: CommonActions
) => {
  switch (action.type) {
    case FETCH_USER_ROLES_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.roles.loadState = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_USER_ROLES_SUCCESS: {
      const newState = produce(state, (draftState) => {
        draftState.roles.list = action.payload.roles;
        draftState.roles.loadState = LoadState.Loaded;
      });
      return newState;
    }
    case FETCH_USER_ROLES_FAILURE: {
      const newState = produce(state, (draftState) => {
        draftState.roles.loadState = LoadState.Failed;
      });
      return newState;
    }
    case FETCH_FAULT_TYPE_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.faultType.loadState = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_FAULT_TYPE_SUCCESS: {
      const { key, data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.faultType.list[key] = data;
        draftState.faultType.loadState = LoadState.Loaded;
      });
      return newState;
    }
    case FETCH_FAULT_TYPE_FAILURE: {
      const newState = produce(state, (draftState) => {
        draftState.faultType.loadState = LoadState.Failed;
      });
      return newState;
    }
    case FETCH_FAULT_NAME_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.faultName.loadState = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_FAULT_NAME_SUCCESS: {
      const { key, data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.faultName.list[key] = data;
        draftState.faultName.loadState = LoadState.Loaded;
      });
      return newState;
    }
    case FETCH_FAULT_NAME_FAILURE: {
      const newState = produce(state, (draftState) => {
        draftState.faultName.loadState = LoadState.Failed;
      });
      return newState;
    }
    case FETCH_FAULT_SUB_TYPE_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.subFaultName.loadState = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_FAULT_SUB_TYPE_SUCCESS: {
      const { key, data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.subFaultName.list[key] = data;
        draftState.subFaultName.loadState = LoadState.Loaded;
      });
      return newState;
    }
    case FETCH_FAULT_SUB_TYPE_FAILURE: {
      const newState = produce(state, (draftState) => {
        draftState.subFaultName.loadState = LoadState.Failed;
      });
      return newState;
    }

    case FETCH_MECHANIC_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.mechanics.loadState = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_MECHANIC_SUCCESS: {
      const newState = produce(state, (draftState) => {
        draftState.mechanics.list = action.payload.mechanics;
        draftState.mechanics.loadState = LoadState.Loaded;
      });
      return newState;
    }
    case FETCH_MECHANIC_FAILURE: {
      const newState = produce(state, (draftState) => {
        draftState.mechanics.loadState = LoadState.Failed;
      });
      return newState;
    }
    case FETCH_STATE_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.state.loadState = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_STATE_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.state.list = data;
        draftState.state.loadState = LoadState.Loaded;
      });
      return newState;
    }
    case FETCH_STATE_FAILURE: {
      const newState = produce(state, (draftState) => {
        draftState.state.loadState = LoadState.Failed;
      });
      return newState;
    }
    case FETCH_CITY_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.city.loadState = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_CITY_SUCCESS: {
      const { key, data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.city.list[key] = data;
        draftState.city.loadState = LoadState.Loaded;
      });
      return newState;
    }
    case FETCH_CITY_FAILURE: {
      const newState = produce(state, (draftState) => {
        draftState.city.loadState = LoadState.Failed;
      });
      return newState;
    }

    case FETCH_PACKAGES_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.packages.loadState = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_PACKAGES_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.packages.list = data;
        draftState.packages.loadState = LoadState.Loaded;
      });
      return newState;
    }
    case FETCH_PACKAGES_FAILURE: {
      const newState = produce(state, (draftState) => {
        draftState.packages.loadState = LoadState.Failed;
      });
      return newState;
    }
    case FETCH_MAKER_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.brands.loadState = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_MAKER_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.brands.list = data;
        draftState.brands.loadState = LoadState.Loaded;
      });
      return newState;
    }
    case FETCH_MAKER_FAILURE: {
      const newState = produce(state, (draftState) => {
        draftState.brands.loadState = LoadState.Failed;
      });
      return newState;
    }
    case FETCH_DUAL_MAKER_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.brands.loadState = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_DUAL_MAKER_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.brands.list = data;
        draftState.brands.loadState = LoadState.Loaded;
      });
      return newState;
    }
    case FETCH_DUAL_MAKER_FAILURE: {
      const newState = produce(state, (draftState) => {
        draftState.brands.loadState = LoadState.Failed;
      });
      return newState;
    }
    case FETCH_MODEL_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.models.loadState = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_MODEL_SUCCESS: {
      const { brandName, data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.models.list[brandName] = data;
        draftState.models.loadState = LoadState.Loaded;
      });
      return newState;
    }
    case FETCH_MODEL_FAILURE: {
      const newState = produce(state, (draftState) => {
        draftState.models.loadState = LoadState.Failed;
      });
      return newState;
    }

    case FETCH_SALES_LEADS_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.salesLeads.loadState = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_SALES_LEADS_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.salesLeads.list = data;
        draftState.salesLeads.loadState = LoadState.Loaded;
      });
      return newState;
    }
    case FETCH_SALES_LEADS_FAILURE: {
      const newState = produce(state, (draftState) => {
        draftState.salesLeads.loadState = LoadState.Failed;
      });
      return newState;
    }

    case FETCH_SFA_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.sfa.loadState = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_SFA_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.sfa.list = data;
        draftState.sfa.loadState = LoadState.Loaded;
      });
      return newState;
    }
    case FETCH_SFA_FAILURE: {
      const newState = produce(state, (draftState) => {
        draftState.sfa.loadState = LoadState.Failed;
      });
      return newState;
    }
    case FETCH_MANAGERS_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.managers.loadState = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_MANAGERS_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.managers.list = data;
        draftState.managers.loadState = LoadState.Loaded;
      });
      return newState;
    }
    case FETCH_MANAGERS_FAILURE: {
      const newState = produce(state, (draftState) => {
        draftState.managers.loadState = LoadState.Failed;
      });
      return newState;
    }
    case FETCH_REFERRALS_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.referrals.loadState = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_REFERRALS_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.referrals.list = data;
        draftState.referrals.loadState = LoadState.Loaded;
      });
      return newState;
    }
    case FETCH_REFERRALS_FAILURE: {
      const newState = produce(state, (draftState) => {
        draftState.referrals.loadState = LoadState.Failed;
      });
      return newState;
    }
    /***************************************** */
    case FETCH_REPORTEE_REFERRALS_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.reportee_referrals.loadState = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_REPORTEE_REFERRALS_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.reportee_referrals.list = data;
        draftState.reportee_referrals.loadState = LoadState.Loaded;
      });
      return newState;
    }
    case FETCH_REPORTEE_REFERRALS_FAILURE: {
      const newState = produce(state, (draftState) => {
        draftState.reportee_referrals.loadState = LoadState.Failed;
      });
      return newState;
    }
    default:
      return state;
  }
};
