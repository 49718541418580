import { CssBaseline, ThemeProvider } from "@mui/material";
import { useSelector } from "react-redux";
import {  useRoutes } from "react-router-dom";
import "./App.css";
import { RTL } from "./layout/fullLayout/customizer/RTL";
import { ThemeSettings } from "./layout/fullLayout/customizer/ThemeSettings";
import { IStoreState } from "./redux/initialStoreState";
import {Router} from "./router/router"

function App() {
  const routing = useRoutes(Router);
  const theme = ThemeSettings();
  const customizer = useSelector(
    (state: IStoreState) => state.customizerReducer
  );
  return (
    <ThemeProvider theme={theme}>
      <RTL direction={customizer.activeDir as "ltr"}>
        <CssBaseline />
        {routing}
      </RTL>
    </ThemeProvider>
  );
}

export default App;
