import { IAuth } from "../contexts/AuthProvider/AuthProvider";

export const getUserAuth = (): string | null => {
  const auth = localStorage.getItem("auth");
  if (auth) {
    return JSON.parse(auth).tokens.access;
  }
  return null;
};


export const getUserAuthDetails = (): IAuth['user'] | null => {
  const auth = localStorage.getItem("auth");
  if (auth) {
    return JSON.parse(auth);
  }
  return null;
};
