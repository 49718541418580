import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth, userRoles } from "../contexts/AuthProvider/AuthProvider";

interface IAuthorizedRouteProps {
  allowedRoles: (keyof typeof userRoles)[];
  children: React.ReactNode;
}

export const AuthorizedRoute: React.FC<IAuthorizedRouteProps> = (props) => {
  const { allowedRoles } = props;
  const { user } = useAuth();

  if (allowedRoles.includes(user.role)) {
    return <>{props.children}</>;
  }

  return <Navigate to="/access" />;
};

export const AllowAccessToComponent: React.FC<IAuthorizedRouteProps> = (
  props
) => {
  const { allowedRoles } = props;
  const { user } = useAuth();

  if (allowedRoles.includes(user.role)) {
    return <>{props.children}</>;
  }

  return <></>;
};
