import dayjs from "dayjs"; // Import dayjs
import { useFormik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { api } from "../../../../api/api";
import { Dialog } from "../../../../components/Dialogs/Dialog";
import {
  CustomFormLabel,
  CustomTextField,
} from "../../../../components/formsComponents";
import { useAuth } from "../../../../contexts/AuthProvider/AuthProvider";
import { useMessagesPopup } from "../../../../contexts/MessagesPopupProvider/MessagesPopupProvider";
import { fetchRegisteredUserAsync } from "../../../../redux/registeredUsers/registeredUsersActions";
import { ICcAssignComplaintProps } from "./interfaces/ICcAssignComplaint";

export const CcAssignComplaint: React.FC<ICcAssignComplaintProps> = (props) => {
  const { open, complaint, onClose } = props;

  const [loading, setLoading] = React.useState(false);
  const auth = useAuth();
  const { showTopSuccessSnackbar, showTopErrorSnackbar } = useMessagesPopup();

  const dispatch = useDispatch<any>();

  // Function to format the date and time
  const formatDateTime = (date: Date) => {
    return dayjs(date).format("YYYY-MM-DD HH:mm:ss");
  };

  const { values, errors, handleChange, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: {
        quick_rsa_help_id: complaint.quick_rsa_help_id,
        mobile_no: complaint.mobile_no,
        vehicle_no: complaint.vehicle_no,
        assign_to_name: "",
        assign_time: formatDateTime(new Date()), // Use the formatDateTime function here
      },
      validate: (values) => {
        const errors: any = {};
        if (values.mobile_no === "-1") {
          errors.service_status_v = "*You can't leave this empty";
        } else if (values.vehicle_no === "-1") {
          errors.complaint_status_v = "*You can't leave this empty";
        }
        return errors;
      },
      onSubmit: async (values) => {
        try {
          setLoading(true);

          if (auth.user.tokens && auth.user.tokens.access) {
            await api.post(
              "/assign-quick-rsa-help",
              {
                ...values,
              },
              {
                headers: {
                  "auth-Token": auth.user.tokens.access,
                },
              }
            );

            dispatch(fetchRegisteredUserAsync());
            showTopSuccessSnackbar("Complaint Assigned successfully!!");
            onClose();
          }
        } catch (err: any) {
          showTopErrorSnackbar(err.response.data.msg);
        } finally {
          setLoading(false);
        }
      },
    });

  return (
    <Dialog
      open={open}
      size="xs"
      title="Assign RSA Request"
      onClose={onClose}
      contentWrappedWithForm={{
        onSubmit: handleSubmit,
      }}
      actions={[
        {
          label: loading ? "Please wait..." : "Submit",
          type: "submit",
          disabled: loading,
          variant: "contained",
        },
      ]}
    >
      <CustomFormLabel>RSA Help ID</CustomFormLabel>
      <CustomTextField
        name="mobile_no"
        fullWidth
        value={values.quick_rsa_help_id}
        onChange={handleChange}
        disabled
      />

      <CustomFormLabel>Assigned To</CustomFormLabel>
      <CustomTextField
        name="assign_to_name"
        fullWidth
        value={values.assign_to_name}
        onChange={handleChange}
      />
    </Dialog>
  );
};
