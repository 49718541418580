import React from "react";
import { IDialogProps } from "./interfaces/IDialogProps";
import {
  Dialog as MuiDialog,
  DialogContent,
  Divider,
  DialogActions,
  Box,
  DialogTitle,
  Button,
} from "@mui/material";
import { CustomTypography } from "../formsComponents";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";

export const Dialog: React.FC<IDialogProps> = (props) => {
  const {
    open,
    title,
    size,
    fullScreen = false,
    hideCloseIcon = false,
    contentWrappedWithForm,
    contentSx,
    actions,
    onClose,
  } = props;

  const generateActions = () => {
    if (actions) {
      return (
        <DialogActions sx={{ marginTop: 1 }}>
          {actions.map((action) => {
            return (
              <Button
                variant={action.variant}
                color="primary"
                type={action.type}
                disabled={action.disabled}
                onClick={action.onClick}
                sx={{ marginRight: "5px" }}
              >
                {action.label}
              </Button>
            );
          })}
        </DialogActions>
      );
    }
  };

  const renderForm = () => {
    const actionsButtons = generateActions();
    if (contentWrappedWithForm) {
      return (
        <form onSubmit={contentWrappedWithForm.onSubmit}>
          <DialogContent>{props.children}</DialogContent>
          <Divider />
          {actionsButtons}
        </form>
      );
    }
    return (
      <>
        <DialogContent sx={contentSx}>{props.children}</DialogContent>
        <Divider />
        {actionsButtons}
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return (
    <MuiDialog
      // sx={{ zIndex: 300 }}
      open={open}
      fullScreen={fullScreen}
      onClose={onClose}
      maxWidth={size}
      fullWidth
      // hideBackdrop
      TransitionComponent={fullScreen ? Transition : undefined}
    >
      <DialogTitle>
        <Box display="flex" justifyContent={"space-between"}>
          <CustomTypography
            variant="h5"
            color="rgb(38, 38, 38)"
            fontWeight={"bold"}
          >
            {title}
          </CustomTypography>
          {!hideCloseIcon && (
            <Box sx={{ cursor: "pointer" }} onClick={onClose}>
              <CloseIcon />
            </Box>
          )}
        </Box>
      </DialogTitle>
      <Divider />
      {renderForm()}
    </MuiDialog>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});
