import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { getUserAuth } from "../../helpers";
import {
  DELETE_REGISTERED_USER,
  FETCH_REGISTERED_USER_PROGRESS,
  FETCH_REGISTERED_USER_SUCCESS,
  FETCH_USER_ROLES_FAILURE,
} from "../constants";
import { IStoreState } from "../initialStoreState";
import { IRegisteredUsers } from "./types";

export const fetchRegisteredUsersProgress = () =>
  action(FETCH_REGISTERED_USER_PROGRESS);
export const fetchRegisteredUsersSuccess = (users: IRegisteredUsers["users"]) =>
  action(FETCH_REGISTERED_USER_SUCCESS, { users });
export const fetchRegisteredUsersError = () => action(FETCH_USER_ROLES_FAILURE);

export const deleteRegisteredUser = (userId: number) => action(DELETE_REGISTERED_USER, {userId});

export const fetchRegisteredUserAsync =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchRegisteredUsersProgress());
      const token = getUserAuth();
      if (token) {
        const res = await api.get("/list-users-for-admin",{headers: {
          "auth-Token": token,
        }});
        dispatch(fetchRegisteredUsersSuccess(res.data.result));
      }
    } catch (err) {
      dispatch(fetchRegisteredUsersError());
    }
  };

  
export const deleteRegisteredUserAsync =
(userId: number, onSuccess: () => void, onError:(message: string) => void): ThunkAction<void, IStoreState, {}, AnyAction> =>
async (dispatch, getState) => {
  try {
    const token = getUserAuth();
    if (token) {
      await api.post("/bo_delete_user_by_admin",{user_id_v: userId},{headers: {
        "auth-Token": token,
      }});
      dispatch(deleteRegisteredUser(userId));
      onSuccess();
    }
  } catch (err: any) {
    onError(err.response.data.msg)
  }
};

