import React, { useEffect, useState } from "react";
import CardBackground from "../../assets/images/backgrounds/3.png";
import { Box, Typography } from "@mui/material";
import { IMembershipCardProps } from "./interfaces/IMembershipCardProps";
import { api } from "../../api/api";
import { useAuth } from "../../contexts/AuthProvider/AuthProvider";

export const MembershipCard: React.FC<IMembershipCardProps> = (props) => {
  const { cardInfo, download, onDownloadComplete } = props;
  const [loading, setLoading] = useState(false);

  const auth = useAuth();

  React.useEffect(() => {
    const downloadMembershipCard = async () => {
      if (auth.user && auth.user.tokens) {
        try {
          setLoading(true);
          const res = await api.get(
            `/download-membership-card?membership_code_v=${cardInfo.membershipCode}`,
            {
              responseType: "blob",
              headers: {
                "auth-Token": auth.user.tokens.access,
              },
            }
          );

          const url = URL.createObjectURL(res.data);
          const link = document.createElement("a");
          link.href = url;
          link.download = `card_${cardInfo.membershipCode}`;
          link.target = "_blank";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          onDownloadComplete();
        } catch (err: any) {
        } finally {
          setLoading(false);
        }
      }
    };

    if (download) {
      downloadMembershipCard();
    }
  }, [download, cardInfo.membershipCode, onDownloadComplete]);

  return (
    <Box
      id="membershipCard"
      sx={{
        display: "none",
        position: "relative",
        borderRadius: "5px",
        width: { md: "70%", xs: "100%" },
      }}
    >
      <img src={CardBackground} style={{ width: "100%" }} alt="cardImage" />
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          top: { md: "22%", xs: "20%" },
        }}
      >
        <Typography
          variant="h1"
          fontWeight={"bold"}
          textAlign={"center"}
          sx={{ color: "#FFCA59", fontSize: { md: "25px", xs: "10px" } }}
        >
          {" Four Wheeler  " + cardInfo.planName.replace(/[^a-zA-Z ]/g, " ")}
        </Typography>

        <Box sx={{ mt: { md: 9, xs: 4 } }}>
          <Typography
            variant="h3"
            fontWeight={"bold"}
            textAlign={"center"}
            sx={{ color: "#fff", fontSize: { md: "35px", xs: "15px" } }}
          >
            {cardInfo.membershipCode.replace(/\d{4}(?=.)/g, "$& ")}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
