import { LocalizationProvider } from "@mui/lab";
import DatePicker from "@mui/lab/DatePicker";
import React from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { CustomTextField } from "./CustomTextField";

export const CustomDatePicker: React.FC<{
  value: string;
  sx?: any;
  onChange: (value: string) => void;
}> = (props) => {
  const { value, sx } = props;
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        value={value}
        inputFormat="DD-MM-YYYY"
        onChange={(newValue) => {
          props.onChange(newValue as string);
        }}
        renderInput={(params) => <CustomTextField {...params} sx={sx} />}
      />
    </LocalizationProvider>
  );
};
