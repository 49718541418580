import React from "react";
import { StandadCard } from "../../../../../components/Cards";
import {
  CustomSelect,
  CustomTypography,
} from "../../../../../components/formsComponents";
import { MenuItem, Box, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { IStoreState } from "../../../../../redux/initialStoreState";
import { fetchTransactionsAsync } from "../../../../../redux/dashboard/dashboardActions";
import { Skeleton } from "@mui/lab";
import { useAuth } from "../../../../../contexts/AuthProvider/AuthProvider";
import {
  CalendarViewMonth,
  DateRange,
  Today,
  ViewWeek,
} from "@mui/icons-material";
import { dateFilterDates } from "../../../../../components/Table/helpers";

const { today, startDateOfMonth, lastweek, tommorow, yesterday } =
  dateFilterDates();

export const UserTransactionCounter: React.FC = () => {
  const [transactionHistoryStatus, setTransactionHistoryStatus] =
    React.useState<"ACTIVE" | "PENDING">("ACTIVE");

  return (
    <StandadCard
      heading="Transactions History"
      rightHeading={
        <CustomSelect
          sx={{ width: "100%", minWidth: "150px" }}
          value={transactionHistoryStatus}
          onChange={(e) =>
            setTransactionHistoryStatus(e.target.value as "ACTIVE")
          }
        >
          <MenuItem value="ACTIVE">ACTIVE</MenuItem>
          <MenuItem value="PENDING">PENDING</MenuItem>
        </CustomSelect>
      }
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <Row
            label="Today"
            fromDate={today}
            todate={tommorow}
            status={transactionHistoryStatus}
            icon={<Today />}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Row
            label="Yesterday"
            fromDate={yesterday}
            todate={yesterday}
            status={transactionHistoryStatus}
            icon={<DateRange />}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <Row
            label="Last Week"
            fromDate={lastweek}
            todate={today}
            status={transactionHistoryStatus}
            icon={<ViewWeek />}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Row
            label="This Month"
            fromDate={startDateOfMonth}
            todate={today}
            status={transactionHistoryStatus}
            icon={<CalendarViewMonth />}
          />
        </Grid>
      </Grid>
    </StandadCard>
  );
};

export const Row: React.FC<{
  label: string;
  fromDate: string;
  todate: string;
  status: "ACTIVE" | "PENDING";
  icon: React.ReactNode;
}> = (props) => {
  const { label, fromDate, todate, status, icon } = props;
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch<any>();
  const [tablePagination, setTablePagination] = React.useState({
    pageNumber: 1,
    rowsInPerPage: 25,
  });

  let transactions = useSelector(
    (state: IStoreState) =>
      state.dashboard.userTransactions.transactionsByDate[
        fromDate + "-" + todate + "-" + status
      ]
  );
  transactions = transactions ? transactions : { list: [], count: 0 };
  const user = useAuth();

  React.useEffect(() => {
    dispatch(
      fetchTransactionsAsync(
        tablePagination.pageNumber,
        tablePagination.rowsInPerPage,
        fromDate,
        todate,
        status,
        {
          onStart: () => setLoading(true),
          onSuccess: () => setLoading(false),
          onError: () => setLoading(false),
        },
        user.user.role,
        false
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tablePagination, fromDate, todate, status]);
  return (
    <Box
      sx={{
        margin: "15px 0px",
        borderRight: "1px solid rgb(240, 240, 240)",
        pl: 3,
        pr: 3,
      }}
    >
      <Box display={"flex"} alignItems="center" justifyContent="space-between">
        <CustomTypography variant="h5" fontWeight="600">
          {label}
        </CustomTypography>
        {icon}
      </Box>
      <CustomTypography
        variant="body1"
        sx={{ mt: "0.5rem !important", fontSize: "0.9rem" }}
      >
        {loading ? <Skeleton width={20} /> : transactions.count}
      </CustomTypography>
    </Box>
  );
};
