import { Chip } from "@mui/material";
import React from "react";
import { ICustomChipProps } from "./interfaces/ICustomChipProps";

export const CustomChip: React.FC<ICustomChipProps> = (props) => {
  const { size, content, color, icon, sx, onClick } = props;
  return (
    <Chip
      sx={{
        color: "#fff",
        borderRadius: "6px",
        pl: "3px",
        pr: "3px",
        ...sx,
      }}
      icon={icon ? React.cloneElement(icon as React.ReactElement) : undefined}
      color={color}
      size={size}
      label={content}
      onClick={onClick}
    />
  );
};
