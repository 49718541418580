import React from "react";
import { Dialog } from "../../../../components/Dialogs/Dialog";
import { Grid } from "@mui/material";
import { StandadCard } from "../../../../components/Cards";
import { StandardCellList } from "../../../../components/Standards";
import { formatDate } from "../../../../helpers";
import { IViewInfoDialogProps } from "./interfaces/IViewInfoDialogProps";
import { capitalize } from "lodash";
import dayjs from "dayjs";

export const ViewInfoDialog: React.FC<IViewInfoDialogProps> = (props) => {
  const { open, rsaData, onClose } = props;
  const formatDateTime = (date?: string) => {
    return date ? dayjs(date).format("YYYY-MM-DD HH:mm:ss") : "--";
  };
  return (
    <Dialog
      open={open}
      title="RSA Information"
      onClose={onClose}
      size="md"
      actions={[
        { type: "button", label: "Close", variant: "text", onClick: onClose },
      ]}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} lg={12}>
          <StandadCard heading="RSA DATA" sx={{ mb: 2 }}>
            <StandardCellList
              colSize={3}
              list={[
                {
                  heading: "RSA Help ID",
                  data: {
                    type: "text",
                    value: "# " + rsaData.quick_rsa_help_id,
                  },
                },

                {
                  heading: "Mobile Number",
                  data: {
                    type: "text",
                    value: rsaData.mobile_no,
                  },
                },
                {
                  heading: "vehicle_no",
                  data: {
                    type: "text",
                    value: rsaData.vehicle_no,
                  },
                },
                {
                  heading: "Category",
                  data: {
                    type: "text",
                    value: rsaData.category,
                    color:
                      rsaData.status === "ACTIVE"
                        ? "succes.main"
                        : "error.main",
                  },
                },
                {
                  heading: "Assigned To",
                  data: {
                    type: "text",
                    value: rsaData.assign_to_name,
                    color:
                      rsaData.status === "ACTIVE"
                        ? "succes.main"
                        : "error.main",
                  },
                },
                {
                  heading: "Create Time",
                  data: {
                    type: "text",
                    value: formatDateTime(rsaData.create_dt),
                  },
                },

                {
                  heading: "Assigned time",
                  data: {
                    type: "text",
                    value: formatDateTime(rsaData.assign_time),
                    color:
                      rsaData.status === "ACTIVE"
                        ? "succes.main"
                        : "error.main",
                  },
                },
                {
                  heading: "Closed time",
                  data: {
                    type: "text",
                    value: formatDateTime(rsaData.close_time),
                    color:
                      rsaData.status === "ACTIVE"
                        ? "succes.main"
                        : "error.main",
                  },
                },

                {
                  heading: "Comment",
                  data: {
                    type: "text",
                    value: capitalize(rsaData.comment.replace(/_/g, " ")),
                  },
                },
                {
                  heading: "Address",
                  data: {
                    type: "text",
                    value: rsaData.address,
                  },
                },
                {
                  heading: "Status",
                  data: {
                    type: "text",
                    value: rsaData.status,
                    color:
                      rsaData.status === "ACTIVE"
                        ? "succes.main"
                        : "error.main",
                  },
                },
                {
                  heading: "Remark",
                  data: {
                    type: "text",
                    value: rsaData.remark,
                  },
                },
              ]}
            />
          </StandadCard>
        </Grid>
      </Grid>
    </Dialog>
  );
};
