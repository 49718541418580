import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Box,
} from "@mui/material";
import React from "react";
import { IErrorDialogProps } from "./interfaces/IDialogProps";
import { Cancel } from "@mui/icons-material";
import { useDialogStyles } from "./useDialogStyles";

export const ErrorDialog: React.FC<IErrorDialogProps> = (props) => {
  const { open, content, onClose } = props;

  const classes = useDialogStyles();
  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth>
        <DialogContent>
          <Typography variant="h5" sx={{ mb: 2 }}>
            Error
          </Typography>
          <Box display={"flex"} justifyContent={"center"}  sx={{ mb: 2 }}>
            <Cancel className={classes.errorIcon} />
          </Box>
          <Typography variant="h6" textAlign={"center"} sx={{ mb: 2 }}>
            {content}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={onClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
