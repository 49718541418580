import React from "react";
import {
  IUpgradePackgeFromProp,
  IUpgradePackgeProp,
} from "../StuckedActivations/interface/iIStuckedActivation";
import { Dialog } from "../../../../../components/Dialogs/Dialog";
import { useFormik } from "formik";
import {
  CustomFormLabel,
  CustomSelect,
  CustomTextField,
  CustomTypography,
} from "../../../../../components/formsComponents";
import { useAuth } from "../../../../../contexts/AuthProvider/AuthProvider";
import { api } from "../../../../../api/api";
import { useMessagesPopup } from "../../../../../contexts/MessagesPopupProvider/MessagesPopupProvider";
import { IMembershipDetails } from "./Interface/IMemberDetails";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { IBreadcrumbProps } from "../../../../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { StandadCard } from "../../../../../components/Cards";
import { PageContainer } from "../../../../../components/container/PageContainer";
import { Breadcrumb } from "../../../../../layout/fullLayout/breadcrumb/Breadcrumb";
import { DisabledByDefault } from "@mui/icons-material";
import { ICompletedRequestProps } from "../../../../generateProductLink/Components/CompletedRequest/interfaces/ICompletedRequestProps";
import { CompletedRequest } from "../../../../generateProductLink/Components/CompletedRequest/CompletedRequest";
import { CompletedUpgradeRequest } from "../../../../generateProductLink/Components/CompletedRequest/CompletedUpgradeRequest";

export const UpgradePackageForm: React.FC = () => {
  const { id } = useParams();

  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/",
      title: "dashboard",
    },
    {
      title: "upgrade package",
    },
  ];

  const [loading, setLoading] = React.useState(false);
  const auth = useAuth();

  const navigate = useNavigate();
  const [membershipCode, setMembershipCode] = React.useState<string>("");
  const [package_id, setPackage_id] = React.useState<number>(0);
  const { showTopSuccessSnackbar, showSuccessMessage, showTopErrorSnackbar } =
    useMessagesPopup();
  const [open, setOpen] = React.useState(true);
  const [data, setData] = React.useState<IMembershipDetails>();
  const [pageLoading, setPageLoading] = React.useState(false);

  const [completedRequest, setCompletedRequest] = React.useState<
    ICompletedRequestProps["generatedLink"] | null
  >(null);

  const { values, errors, handleChange, resetForm, handleSubmit, setValues } =
    useFormik({
      initialValues: {
        user_name_v: "",
        mobile_number_v: "",
        email_id_v: "",
        state_v: "",
        city_v: "",
        area_v: "",
        p_code_v: "",
        address_v: "",
        gst_no_v: "",
        vehicle_no_v: "",
        maker_name_v: "",
        model_name_v: "",
        registration_year_v: 0,
        old_package_id_v: 0,
        package_id_v: package_id,
        membership_code_v: membershipCode,
        package_price_v: "",
        payment_mode_v: "-1",
        referral_code_v: "",
        iteration_v: 1,
        remark_v: "",
        link_id_v: 0,
      },

      validate: (values) => {
        const errors: any = {};
        if (!values.package_id_v) {
          errors.package_id_v = "*Package is requried field.";
        } else if (!values.vehicle_no_v) {
          errors.vehicle_no_v = "*Vehicle number is required";
        }
        return errors;
      },
      onSubmit: async (values) => {
        if (auth.user && auth.user.tokens) {
          try {
            fetchDetails();
            setLoading(true);
            const res = await api.post("/prc-upgrade-package", values, {
              headers: {
                "auth-Token": auth.user.tokens.access,
              },
            });
            setCompletedRequest(res.data);
            window.scrollTo(0, 0);
          } catch (err: any) {
            showTopErrorSnackbar(err.response.data.msg);
          } finally {
            setLoading(false);
          }
        }
      },
    });
  const fetchDetails = async () => {
    if (auth.user && auth.user.tokens) {
      try {
        setPageLoading(true);
        let URL = `/get-user-details?membership_code_v=${id}`;
        const res = await api.get(URL, {
          headers: {
            "auth-Token": auth.user.tokens.access,
          },
        });

        const result = res.data;
        setData(res.data);
        setValues({
          user_name_v: result.user_info.user_name,
          mobile_number_v: result.user_info.contact_no,
          email_id_v: result.user_info.email_id,
          state_v: result.user_info.state_id,
          city_v: result.user_info.city_id,
          area_v: result.user_info.area,
          p_code_v: result.user_info.pin_code,
          address_v: result.user_info.address,
          gst_no_v: result.user_info.gstin,
          vehicle_no_v: result.user_info.vehicle_no,
          maker_name_v: result.user_info.make_name,
          model_name_v: result.user_info.model_name,
          registration_year_v: result.user_info.registration_year,
          old_package_id_v: result.package.package_id,
          package_id_v: package_id,
          membership_code_v: membershipCode,
          package_price_v: "",
          payment_mode_v: "",
          referral_code_v: result.user_info.referral_code,
          iteration_v: 1,
          remark_v: "Auto Upgrade",
          link_id_v: 0,
        });
      } catch (err: any) {
        // Handle error if needed
      }
    }
  };
  const handleReset = () => {
    // setSelectedProduct(null);
    setCompletedRequest(null);
    resetForm();
  };

  React.useEffect(() => {
    if (id) {
      fetchDetails();
    }
  }, [id]);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <PageContainer
      title="Upgarde/Edit Membership"
      description="this is innerpage"
    >
      <Breadcrumb title="" items={BCrumb} />
      <StandadCard heading="Choose plan and Generate Product Link">
        {!completedRequest && (
          <>
            <Grid item xs={12} md={7}>
              <StandadCard>
                <form onSubmit={handleSubmit}>
                  <Typography
                    variant="h5"
                    color={"primary"}
                    fontWeight={600}
                    sx={{ mb: 1 }}
                  >
                    Upgrade the Package
                  </Typography>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={12}>
                      <StandadCard
                        heading="Memberhip Details"
                        sx={{ border: 0 }}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={4}>
                            <CustomFormLabel>Customer Name</CustomFormLabel>
                            <CustomTextField
                              fullWidth
                              name="user_name_v"
                              value={values.user_name_v}
                              onChange={handleChange}
                              error={errors.user_name_v ? true : false}
                              helperText={errors.user_name_v}
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <CustomFormLabel>Contact Number</CustomFormLabel>
                            <CustomTextField
                              fullWidth
                              name="mobile_number_v"
                              value={values.mobile_number_v}
                              onChange={handleChange}
                              error={errors.mobile_number_v ? true : false}
                              helperText={errors.mobile_number_v}
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <CustomFormLabel>Membership Code</CustomFormLabel>
                            <CustomTextField
                              fullWidth
                              name="membership_code_v"
                              value={id}
                              onChange={handleChange}
                              error={errors.membership_code_v ? true : false}
                              helperText={errors.membership_code_v}
                              disabled
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <CustomFormLabel>Email ID</CustomFormLabel>
                            {data && data.user_info && (
                              <CustomTextField
                                fullWidth
                                value={data.user_info.email_id}
                              />
                            )}
                          </Grid>

                          <Grid item xs={12} md={4}>
                            <CustomFormLabel>Status</CustomFormLabel>
                            {data && data.user_info && (
                              <CustomTextField
                                fullWidth
                                value={data.user_info.status}
                                disabled
                              />
                            )}
                          </Grid>

                          <Grid item xs={12} md={4}>
                            <CustomFormLabel>GST No.</CustomFormLabel>
                            <CustomTextField
                              fullWidth
                              name="gst_no_v"
                              value={values.gst_no_v}
                              onChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <CustomFormLabel>City</CustomFormLabel>
                            <CustomTextField
                              fullWidth
                              name="city_v"
                              value={values.city_v}
                              onChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <CustomFormLabel>State</CustomFormLabel>
                            <CustomTextField
                              fullWidth
                              name="state_v"
                              value={values.state_v}
                              onChange={handleChange}
                            />
                          </Grid>

                          <Grid item xs={12} md={12}>
                            <CustomFormLabel> Address</CustomFormLabel>
                            <CustomTextField
                              fullWidth
                              multiline
                              rows={3}
                              name="address_v"
                              value={values.address_v}
                              onChange={handleChange}
                            />
                          </Grid>
                        </Grid>
                      </StandadCard>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <StandadCard heading="Vehicle Info" sx={{ border: 0 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={4}>
                            <CustomFormLabel>Vehilce Number</CustomFormLabel>
                            <CustomTextField
                              fullWidth
                              name="vehicle_no_v"
                              value={values.vehicle_no_v}
                              onChange={handleChange}
                              error={errors.vehicle_no_v ? true : false}
                              helperText={errors.vehicle_no_v}
                            />
                          </Grid>

                          <Grid item xs={12} md={4}>
                            <CustomFormLabel>Brand</CustomFormLabel>
                            <CustomTextField
                              fullWidth
                              name="maker_name_v"
                              value={values.maker_name_v}
                              onChange={handleChange}
                            />
                          </Grid>

                          <Grid item xs={12} md={4}>
                            <CustomFormLabel>Model</CustomFormLabel>
                            <CustomTextField
                              fullWidth
                              name="model_name_v"
                              value={values.model_name_v}
                              onChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <CustomFormLabel>Registration Year</CustomFormLabel>
                            <CustomTextField
                              fullWidth
                              name="registration_year_v"
                              value={values.registration_year_v}
                              onChange={handleChange}
                            />
                          </Grid>
                        </Grid>
                      </StandadCard>
                      <StandadCard heading="Package Info" sx={{ border: 0 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={4}>
                            <CustomFormLabel>Current Package</CustomFormLabel>
                            {data && data.package && (
                              <CustomTextField
                                fullWidth
                                value={data.package.package_name}
                                disabled
                              />
                            )}
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <CustomFormLabel>
                              Current Package Price
                            </CustomFormLabel>
                            {data && data.package && (
                              <CustomTextField
                                fullWidth
                                value={data.package.package_price}
                                disabled
                              />
                            )}
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <CustomFormLabel>
                              Current Package Services
                            </CustomFormLabel>
                            {data && data.package && (
                              <CustomTextField
                                fullWidth
                                value={data.package.no_of_services}
                                disabled
                              />
                            )}
                          </Grid>

                          <Grid item xs={12} md={4}>
                            <CustomFormLabel>New Package</CustomFormLabel>

                            <CustomSelect
                              sx={{ minWidth: "120px" }}
                              value={values.package_id_v}
                              onChange={(e) => {
                                const selectedPackageId = e.target
                                  .value as number;
                                setPackage_id(selectedPackageId);
                                setValues({
                                  ...values,
                                  package_id_v: selectedPackageId,
                                });
                                console.log(selectedPackageId);
                              }}
                              id="package_id_v"
                              variant="outlined"
                              size="small"
                              fullWidth
                              error={errors.package_id_v ? true : false}
                            >
                              <MenuItem value="0">Select Package</MenuItem>
                              <MenuItem value="2">Gold Pan India</MenuItem>
                              <MenuItem value="6">Platinum pan India</MenuItem>
                              <MenuItem value="11">Titanium pan India</MenuItem>
                              <MenuItem value="19">LifeTime pan India</MenuItem>
                              <MenuItem value="21">
                                platinum Family Plan
                              </MenuItem>
                              <MenuItem value="22">
                                Titanium Family Plan
                              </MenuItem>
                              <MenuItem value="23">
                                LifeTime Family Plan
                              </MenuItem>
                              <MenuItem value="1">Gold (Delhi NCR)</MenuItem>
                            </CustomSelect>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <CustomFormLabel>New Package Price</CustomFormLabel>
                            <CustomTextField
                              fullWidth
                              name="package_price_v"
                              value={values.package_price_v}
                              onChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={12} lg={4}>
                            <CustomFormLabel>Payment Mode</CustomFormLabel>
                            <CustomSelect
                              fullWidth
                              name="payment_mode_v"
                              value={values.payment_mode_v}
                              onChange={handleChange}
                              error={errors.payment_mode_v ? true : false}
                            >
                              <MenuItem value="-1">
                                Select Payment Mode
                              </MenuItem>
                              <MenuItem value="WEBSITE">WEBSITE</MenuItem>
                              <MenuItem value="ONLINE">ONLINE</MenuItem>
                              <MenuItem value="CHEQUE">CHEQUE</MenuItem>
                              <MenuItem value="CASH">CASH</MenuItem>
                            </CustomSelect>
                          </Grid>
                        </Grid>
                        <Box sx={{ mt: 3 }}>
                          <Button
                            sx={{ width: "20%" }}
                            disabled={loading}
                            variant="contained"
                            type="submit"
                          >
                            UPGRADE
                          </Button>
                        </Box>
                      </StandadCard>
                    </Grid>
                  </Grid>
                </form>
              </StandadCard>
            </Grid>
          </>
        )}
        {completedRequest && (
          <CompletedUpgradeRequest
            generatedLink={completedRequest}
            // product={selectedProduct}
            productLink={values}
            onCreateNew={handleReset}
          />
        )}
      </StandadCard>
    </PageContainer>
  );
};
