import { makeStyles } from "@mui/styles";

export const useDialogStyles = makeStyles({
  errorIcon: {
    fontSize: 80,
    "& path": {
      fill: "red",
    },
  },
  successIcon: {
    fontSize: 80,
    "& path": {
      fill: "green",
    },
  },
  alarmIcon: {
    fontSize: 80,
    "& path": {
      fill: "red",
    },
  },
});
