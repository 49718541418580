import { IComplaint } from "../../constants/commonTypes";

export interface ISowUserTransaction {
  sow_user_prospect_id: number;
  olddb_tempid: number | null;
  user_name: string;
  contact_no: string;
  email_id: string;
  state: string;
  membership_code: string;
  city: string;
  area: string;
  address: string;
  gstin: string;
  vehicle_no: string;
  make_name: string;
  model_name: string;
  fuel_type: string;
  package_name: string;
  package_id: number;
  package_price: number;
  package_period: number;
  package_services: number;
  comment: string;
  payment_date: string;
  payment_mode: string;
  promo_code: string;
  referral_code: string;
  status: "ACTIVE" | "PENDING";
  hof_id: number | null;
  partner_id: number;
  gst_percentage: number;
  gst_amount: number;
  insert_dt: string;
  branch_office: string;
  vehicle_number: string;
  maker_name: string;
  total_services: number;
  used_services: number;
  contact_number: string;
  remain_services: number;
  membership_start_date: string;
  membership_expiry_date: string;
  new?: boolean;
}

export interface IManageMembership {
  user_name: string;
  contact_number: string;
  email_id: string;
  vehicle_number: string;
  maker_name: string;
  model_name: string;
  state: string;
  city: string;
  area: string;
  address: string;
  status: string;
  total_services: number;
  used_services: number;
  remain_services: number;
  membership_start_date: string;
  membership_expiry_date: string;
  branch_office: string;
  membership_code: string;
}

export interface ISowDashbaord {
  sowUserTransactions: {
    transactionsByDate: {
      [key: string]:
        | {
            list: ISowUserTransaction[];
            count: number;
          }
        | undefined;
    };
  };
  complaints: {
    compaintsByDate: {
      [key: string]:
        | {
            list: IComplaint[];
            count: number;
          }
        | undefined;
    };
  };
}

export const initialSowDashboard: ISowDashbaord = {
  sowUserTransactions: {
    transactionsByDate: {},
  },
  complaints: {
    compaintsByDate: {},
  },
};
