import CloseIcon from "@mui/icons-material/Close";
import { Alert, IconButton, Snackbar } from "@mui/material";
import React from "react";
import { ErrorDialog } from "../../components/Dialogs/ErrorDialog";
import { SuccessDialog } from "../../components/Dialogs/SuccessDialog";
import { AlarmDialog } from "../../components/Dialogs/AlarmDialog";

interface IMessagesPopup {
  showErrorMessage: (message: string | null) => void;
  showSuccessMessage: (message: string | React.ReactNode | null) => void;
  showTopSuccessSnackbar: (message: string | null) => void;
  showTopErrorSnackbar: (message: string | null) => void;
  showTopWarningSnackbar: (message: string | null) => void;
  showAudioDialog: (message: string | null) => void;
}

const MessagesPopupContext = React.createContext<IMessagesPopup>({
  showErrorMessage: () => {},
  showSuccessMessage: () => {},
  showTopSuccessSnackbar: () => {},
  showTopErrorSnackbar: () => {},
  showTopWarningSnackbar: () => {},
  showAudioDialog: () => {},
});

export const useMessagesPopup = () => React.useContext(MessagesPopupContext);

export const MessagesPopupProvider: React.FC<{ children: React.ReactNode }> = (
  props
) => {
  const [errorPopup, setErrorPopup] = React.useState<string | null>(null);
  const [successPopup, setSuccessPopup] = React.useState<
    string | React.ReactNode | null
  >(null);
  const [audioPopup, setAudioPopup] = React.useState<
    string | React.ReactNode | null
  >(null);
  const [errorSnackbar, setErrorSnackbar] = React.useState<string | null>(null);
  const [successSnackbar, setSuccessSnackbar] = React.useState<
    string | React.ReactNode | null
  >(null);
  const [warningSnackbar, setWarningSnackbar] = React.useState<string | null>(
    null
  );

  const handleShowPopup =
    (type: "error" | "success") =>
    (message: string | React.ReactNode | null) => {
      if (type === "error") {
        setErrorPopup(message as string);
      } else {
        setSuccessPopup(message);
      }
    };

  const handleShowSnackBar =
    (type: "error" | "success" | "warning") => (message: string | null) => {
      if (type === "error") {
        setErrorSnackbar(message);
      } else if (type === "warning") {
        setWarningSnackbar(message);
      } else {
        setSuccessSnackbar(message);
      }
    };

  const handleShowAudioDialog =
    (type: "audio") => (message: string | React.ReactNode | null) => {
      setAudioPopup(message);
    };

  return (
    <>
      <MessagesPopupContext.Provider
        value={{
          showErrorMessage: handleShowPopup("error"),
          showSuccessMessage: handleShowPopup("success"),
          showTopErrorSnackbar: handleShowSnackBar("error"),
          showTopSuccessSnackbar: handleShowSnackBar("success"),
          showTopWarningSnackbar: handleShowSnackBar("warning"),
          showAudioDialog: handleShowAudioDialog("audio"),
        }}
      >
        {props.children}
      </MessagesPopupContext.Provider>
      {errorPopup && (
        <ErrorDialog
          open={true}
          content={errorPopup}
          onClose={() => setErrorPopup(null)}
        />
      )}
      {successPopup && (
        <SuccessDialog
          open={true}
          content={successPopup}
          onClose={() => setSuccessPopup(null)}
        />
      )}
      {audioPopup && (
        <AlarmDialog
          open={true}
          content={audioPopup}
          onClose={() => setAudioPopup(null)}
        />
      )}
      {errorSnackbar && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={Boolean(errorSnackbar)}
          autoHideDuration={5000}
          onClose={() => setErrorSnackbar(null)}
          key={"topright"}
        >
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setErrorSnackbar(null);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {errorSnackbar}
          </Alert>
        </Snackbar>
      )}
      {successSnackbar && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={Boolean(successSnackbar)}
          autoHideDuration={5000}
          onClose={() => setSuccessSnackbar(null)}
          key={"topright"}
        >
          <Alert
            severity="success"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setSuccessSnackbar(null);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {successSnackbar}
          </Alert>
        </Snackbar>
      )}
      {warningSnackbar && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={Boolean(warningSnackbar)}
          autoHideDuration={5000}
          onClose={() => setWarningSnackbar(null)}
          key={"topright"}
        >
          <Alert
            severity="warning"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setWarningSnackbar(null);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {warningSnackbar}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};
